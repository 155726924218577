import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import useSyncContext from '../../hooks/useSyncContext/useSyncContext';
import { useAppState } from '../../state';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      background: 'rgba(0,0,0,0.04)',
      height: '100%',
      padding: '1rem',
    },
    frame: {
      width: '100%',
      height: '100%',
      background: 'white',
      border: '0.1em solid #CACDD8',
      padding: '1rem',
      overflow: 'auto',
    },
  })
);

const NotePad = (props: { padId: string }) => {
  const classes = useStyles();
  const [content, setContent] = useState('');
  const { userDocumentNotes } = useSyncContext();
  const { appDispatch } = useAppState();

  useEffect(() => {
    if (userDocumentNotes && userDocumentNotes.data) {
      if ((userDocumentNotes?.data as any)?.content) {
        setContent((userDocumentNotes?.data as any)?.content?.[props.padId]);
      }

      const handleUpdate = (update: any) => {
        appDispatch({ type: 'set-notes-update', hasNotesUpdate: true });
        setContent(update.data.content?.[props.padId]);
      };

      userDocumentNotes.on('updated', handleUpdate);
      return () => {
        userDocumentNotes.off('updated', handleUpdate);
      };
    }
  }, [userDocumentNotes, props.padId, appDispatch]);

  return (
    <div className={clsx(classes.container)}>
      <div className={clsx(classes.frame)} id="notepad" dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};

export default NotePad;
