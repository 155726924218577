import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, Box, Typography } from '@material-ui/core';
import { Message } from '@twilio/conversations';
import { Conversation } from '@twilio/conversations';
import MessageInfo from './MessageInfo/MessageInfo';
import MessageListScrollContainer from './MessageListScrollContainer/MessageListScrollContainer';
import TextMessage from './TextMessage/TextMessage';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import useSyncContext from '../../../hooks/useSyncContext/useSyncContext';
import { useEnqueueSnackbar } from '../../../hooks/useSnackbar/useSnackbar';
import MediaMessage from './MediaMessage/MediaMessage';
import { MessageMenu } from './MessageMenu/MessageMenu';
import { useAppState } from '../../../state';

interface MessageListProps {
  conversation: Conversation | null;
  messages: Message[];
  canModerate: boolean;
  isAnnouncemenTab: boolean;
  chatBans: any;
  eventBans: any;
  globalAppState: any;
  setAnnouncements: any;
  pubcode: string;
}

const useStyles = makeStyles({
  viewerContainer: {
    position: 'relative',
    padding: '0.5em 0 0.5em 0',
    '& button': {
      display: 'none',
      position: 'absolute',
      top: '50%',
      right: '0',
      padding: '0',
      minWidth: 'auto',
      transform: 'translate(0, -50%)',
    },
    '&:hover': {
      background: 'rgba(0,0,0,0.04)',
    },
    '&:hover button': {
      display: 'inline-flex',
    },
  },
  refreshLink: {
    cursor: 'pointer',
  },
});

const getFormattedTime = (message: Message) =>
  message.dateCreated?.toLocaleTimeString('en-us', { hour: 'numeric', minute: 'numeric' }).toLowerCase();

export default function MessageList({
  conversation,
  messages,
  canModerate,
  isAnnouncemenTab,
  chatBans,
  eventBans,
  globalAppState,
  setAnnouncements,
  pubcode,
}: MessageListProps) {
  const { room } = useVideoContext();
  const { appState } = useAppState();
  const { userDocument } = useSyncContext();
  const localParticipant = room?.localParticipant;

  const enqueueSnackbar = useEnqueueSnackbar();
  const announcementArray = [] as string[];

  const classes = useStyles();

  const updateAnnouncements = () => {
    setAnnouncements([]);
    globalAppState.announcements?.forEach((item: any) => {
      conversation?.getMessages(1, item).then(res => {
        // possible bug when toggle chat tabs quickly
        if (isAnnouncemenTab) {
          setAnnouncements((oldAnnouncements: any) => [...oldAnnouncements, res.items[0]]);
        }
      });
    });
  };

  if (isAnnouncemenTab) {
    if (messages.length < globalAppState?.announcements?.length)
      return (
        <Box style={{ padding: '20px' }}>
          <Typography variant="body1" align="center">
            Loading Announcements ...
          </Typography>
          <Typography variant="body1" align="center">
            <Link target="_blank" rel="noreferrer" onClick={updateAnnouncements} className={classes.refreshLink}>
              Retry
            </Link>
          </Typography>
        </Box>
      );

    if (messages.length === globalAppState?.announcements?.length) {
      messages.sort(function(a, b) {
        return a.index - b.index;
      });
    }
  }

  return (
    <MessageListScrollContainer messages={messages} isAnnouncemenTab={isAnnouncemenTab}>
      {messages.map((message, idx) => {
        const time = getFormattedTime(message)!;
        const shouldDisplayMessageInfo = true;
        //const previousTime = getFormattedTime(messages[idx - 1]);

        // Display the MessageInfo component when the author or formatted timestamp differs from the previous message
        //const shouldDisplayMessageInfo = time !== previousTime || message.author !== messages[idx - 1]?.author;

        const isLocalParticipant = localParticipant?.identity === message.author;

        let blockedBodyText = '';
        let isHost = false;
        let isModerator = false;
        let hasLink = false;
        let isAnnouncement = false;

        if (message.attributes?.hasOwnProperty('block_chat') && (message.attributes as any)['block_chat'] === true) {
          blockedBodyText = '[message removed]';
        }

        if (
          message.attributes?.hasOwnProperty('block_chat_participant') &&
          (message.attributes as any)['block_chat_participant'] === true
        ) {
          if (userDocument && 'block_participants' in userDocument?.data) {
            if ((userDocument.data as any)?.block_participants?.includes(message.author)) {
              blockedBodyText = '[message hidden]';
            }
          }
        }

        if (
          message.attributes?.hasOwnProperty('host_announcement') &&
          (message.attributes as any)['host_announcement'] === true
        ) {
          if (!canModerate) {
            announcementArray.push(message.sid);
          }

          isAnnouncement = true;
        }

        if (
          message.attributes?.hasOwnProperty('retract_chat') &&
          (message.attributes as any)['retract_chat'] === true
        ) {
          blockedBodyText = '[message retracted]';
        }

        if (chatBans.includes(message.author) && canModerate) {
          blockedBodyText = '[user muted]';
        }

        if (message.attributes?.hasOwnProperty('host_chat') && (message.attributes as any)['host_chat'] === true) {
          isHost = true;
        }

        if (
          message.attributes?.hasOwnProperty('moderator_chat') &&
          (message.attributes as any)['moderator_chat'] === true
        ) {
          isModerator = true;
        }

        if (message.attributes?.hasOwnProperty('has_link') && (message.attributes as any)['has_link'] === true) {
          hasLink = true;
        }

        if (
          message.body &&
          hasLink &&
          globalAppState.isChatLinkEnabled === false &&
          appState.participantName !== message.author &&
          !isHost &&
          !isModerator
        ) {
          blockedBodyText = '[message hidden]';
        }

        if (chatBans.includes(message.author) && appState.participantName !== message.author && !canModerate) {
          return null;
        }

        if (isAnnouncemenTab && !isAnnouncement) {
          return null;
        }

        if (!isAnnouncemenTab && isAnnouncement && messages.length - 1 === idx) {
          if (!(userDocument?.data as any)?.announcements?.includes(message.sid)) {
            userDocument?.update({ announcements: announcementArray }).then(() => {
              // should only alert as msg comes in
              enqueueSnackbar(
                {
                  headline: '',
                  message: message.body,
                  variant: pubcode === 'TKO' ? 'announcement-tko' : 'announcement',
                },
                {
                  key: message.sid,
                  persist: true,
                  preventDuplicate: true,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                  },
                }
              );
            });
          }
        }

        return (
          <React.Fragment key={message.sid}>
            {(shouldDisplayMessageInfo || isAnnouncemenTab) && (
              <MessageInfo
                author={message.author ? message.author : ''}
                authorStatus={canModerate ? blockedBodyText : ''}
                isLocalParticipant={isLocalParticipant}
                isHost={isHost}
                isModerator={isModerator}
                isAnnouncement={isAnnouncement}
                dateCreated={time}
              />
            )}
            {message.type === 'text' && (
              <div className={classes.viewerContainer}>
                <TextMessage
                  body={blockedBodyText !== '' && !canModerate ? blockedBodyText : message.body ? message.body : ''}
                />
                {(((message.attributes as any)['retract_chat'] !== true && !isAnnouncemenTab) || canModerate) && (
                  <MessageMenu
                    blockedBodyText={blockedBodyText}
                    message={message}
                    chatBans={chatBans}
                    eventBans={eventBans}
                    canModerate={canModerate}
                    appState={appState}
                  />
                )}
              </div>
            )}
            {message.type === 'media' && message.attachedMedia && <MediaMessage media={message.attachedMedia[0]} />}
          </React.Fragment>
        );
      })}
    </MessageListScrollContainer>
  );
}
