import React, { useState, useEffect } from 'react';
import { ActiveScreen } from '../../state/appState/appReducer';
import {
  createStream,
  joinStreamAsHost,
  joinStreamAsSpeaker,
  joinStreamAsModerator,
  joinStreamAsViewer,
  connectViewerToPlayer,
  connectModeratorToPlayer,
  getRooms,
} from '../../state/api/api';
import CreateNewEventScreen from './CreateNewEventScreen/CreateNewEventScreen';
import JoinHostEventScreen from './JoinHostEventScreen/JoinHostEventScreen';
import CreateOrJoinScreen from './CreateOrJoinScreen/CreateOrJoinScreen';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import JoinEventScreen from './JoinEventScreen/JoinEventScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import { LoadingScreen } from './LoadingScreen/LoadingScreen';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import ParticipantNameScreen from './ParticipantNameScreen/ParticipantNameScreen';
import SpeakerOrViewerScreen from './SpeakerOrViewerScreen/SpeakerOrViewerScreen';
import { useAppState } from '../../state';
import useChatContext from '../../hooks/useChatContext/useChatContext';
import { useEnqueueSnackbar } from '../../hooks/useSnackbar/useSnackbar';
import usePlayerContext from '../../hooks/usePlayerContext/usePlayerContext';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useSyncContext from '../../hooks/useSyncContext/useSyncContext';

export default function PreJoinScreens() {
  const { getAudioAndVideoTracks } = useVideoContext();
  const { connect: chatConnect } = useChatContext();
  const { connect: videoConnect } = useVideoContext();
  const { connect: playerConnect, disconnect: playerDisconnect } = usePlayerContext();
  const { connect: syncConnect, registerUserDocument, registerSyncMaps } = useSyncContext();
  const [mediaError, setMediaError] = useState<Error>();
  const { appState, user, appDispatch } = useAppState();
  const enqueueSnackbar = useEnqueueSnackbar();

  async function connect() {
    appDispatch({ type: 'set-is-loading', isLoading: true });

    try {
      if (appState.hasSpeakerInvite) {
        const { data } = await joinStreamAsSpeaker(appState.participantName, appState.eventName);
        await videoConnect(data.token);
        appDispatch({ type: 'set-room-sid', roomSid: data.room_sid });
        chatConnect(data.token);
        registerSyncMaps(data.sync_object_names);
        playerDisconnect();
        appDispatch({ type: 'set-is-loading', isLoading: false });
        appDispatch({ type: 'set-has-speaker-invite', hasSpeakerInvite: false });
        return;
      }

      switch (appState.participantType) {
        case 'host': {
          const { data } = await createStream(appState.participantName, appState.eventName);
          syncConnect(data.token);
          await videoConnect(data.token);
          registerUserDocument(data.sync_object_names.user_document_notepad);
          registerSyncMaps(data.sync_object_names);
          appDispatch({ type: 'set-room-sid', roomSid: data.room_sid });
          chatConnect(data.token);
          break;
        }

        case 'guest': {
          // notify app we are host & rejoin
          appDispatch({ type: 'set-participant-type', participantType: 'host' });
          const { data } = await joinStreamAsHost(appState.participantName, appState.eventName);
          // below is duplicate of speaker
          syncConnect(data.token);
          await videoConnect(data.token);
          registerUserDocument(data.sync_object_names.user_document_notepad);
          registerSyncMaps(data.sync_object_names);
          appDispatch({ type: 'set-room-sid', roomSid: data.room_sid });
          chatConnect(data.token);
          break;
        }

        case 'speaker': {
          const { data } = await joinStreamAsSpeaker(appState.participantName, appState.eventName);
          syncConnect(data.token);
          await videoConnect(data.token);
          registerUserDocument(data.sync_object_names.user_document_notepad);
          registerSyncMaps(data.sync_object_names);
          appDispatch({ type: 'set-room-sid', roomSid: data.room_sid });
          chatConnect(data.token);
          break;
        }

        case 'moderator': {
          const { data } = await joinStreamAsModerator(appState.participantName, appState.eventName);
          syncConnect(data.token);
          await playerConnect(data.token);
          registerUserDocument(data.sync_object_names.user_document_notepad);
          registerSyncMaps(data.sync_object_names);
          await connectModeratorToPlayer(appState.participantName, appState.eventName);
          appDispatch({ type: 'set-room-sid', roomSid: data.room_sid });
          chatConnect(data.token);
          break;
        }

        case 'viewer': {
          const { data } = await joinStreamAsViewer(appState.participantName, appState.eventName);
          syncConnect(data.token);

          await playerConnect(data.token);
          registerUserDocument(data.sync_object_names.user_document);
          registerUserDocument(data.sync_object_names.user_document_notepad);
          registerSyncMaps(data.sync_object_names);
          await connectViewerToPlayer(appState.participantName, appState.eventName);
          appDispatch({ type: 'set-room-sid', roomSid: data.room_sid });
          chatConnect(data.token);
          break;
        }
      }
      appDispatch({ type: 'set-is-loading', isLoading: false });
    } catch (e) {
      if (e) {
        appDispatch({ type: 'set-is-loading', isLoading: false });
        appDispatch({ type: 'set-event-name', eventName: '' });

        if ((e as any).response?.data?.error?.explanation === 'Room exists') {
          enqueueSnackbar({
            headline: 'Error',
            message: 'An event already exists with that name. Try creating an event with a different name.',
            variant: 'error',
          });
        } else if ((e as any).response?.data?.error?.message === 'error finding room') {
          enqueueSnackbar({
            headline: 'Error',
            message: 'Event cannot be found. Please check the event name and try again.',
            variant: 'error',
          });
        } else if ((e as any).response?.data?.error?.message === 'Invalid pubcode.') {
          enqueueSnackbar({
            headline: 'Error',
            message: 'Incorrectly formatted room name. Please check the event name and try again.',
            variant: 'error',
          });
        } else if ((e as any).response?.data?.error?.message === 'No permission.') {
          enqueueSnackbar({
            headline: 'Error',
            message: 'User does not have permission to view the event. Please contact customer service.',
            variant: 'error',
          });
        } else {
          enqueueSnackbar({
            headline: 'Error',
            message: 'There was an error while connecting to the event.',
            variant: 'error',
          });
        }
      } else {
        enqueueSnackbar({
          headline: 'Error',
          message: 'There was an error while connecting to the event.',
          variant: 'error',
        });
      }

      // console.log('Error connecting: ', e.toJSON ? e.toJSON() : e);
    }
  }

  useEffect(() => {
    if (appState.activeScreen === ActiveScreen.DeviceSelectionScreen && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, appState.activeScreen, mediaError]);

  return (
    <IntroContainer transparentBackground={appState.hasSpeakerInvite}>
      {appState.participantType === 'host' && <MediaErrorSnackbar error={mediaError} />}

      {appState.isLoading ? (
        <LoadingScreen state={appState} />
      ) : (
        {
          [ActiveScreen.ParticipantNameScreen]: (
            <ParticipantNameScreen state={appState} dispatch={appDispatch} user={user} />
          ),
          [ActiveScreen.CreateOrJoinScreen]: <CreateOrJoinScreen state={appState} dispatch={appDispatch} user={user} />,
          [ActiveScreen.CreateNewEventScreen]: (
            <CreateNewEventScreen state={appState} dispatch={appDispatch} connect={connect} user={user} />
          ),
          [ActiveScreen.JoinHostEventScreen]: (
            <JoinHostEventScreen
              state={appState}
              dispatch={appDispatch}
              connect={connect}
              getRooms={getRooms}
              user={user}
            />
          ),
          [ActiveScreen.SpeakerOrViewerScreen]: (
            <SpeakerOrViewerScreen state={appState} dispatch={appDispatch} user={user} />
          ),
          [ActiveScreen.JoinEventNameScreen]: (
            <JoinEventScreen
              state={appState}
              dispatch={appDispatch}
              connect={connect}
              getRooms={getRooms}
              user={user}
            />
          ),
          [ActiveScreen.DeviceSelectionScreen]: (
            <DeviceSelectionScreen state={appState} dispatch={appDispatch} connect={connect} />
          ),
        }[appState.activeScreen]
      )}
    </IntroContainer>
  );
}
