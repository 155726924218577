import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { Client } from '@twilio/conversations';
import { Conversation } from '@twilio/conversations';
import { Message } from '@twilio/conversations';
import { useAppState } from '../../state';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import usePlayerContext from '../../hooks/usePlayerContext/usePlayerContext';

type ChatContextType = {
  isChatWindowOpen: boolean;
  setIsChatWindowOpen: (isChatWindowOpen: boolean) => void;
  connect: (token: string) => void;
  disconnect: () => void;
  hasUnreadMessages: boolean;
  messages: Message[];
  conversation: Conversation | null;
};

export const ChatContext = createContext<ChatContextType>(null!);

export const ChatProvider: React.FC = ({ children }) => {
  const { room, onError } = useVideoContext();
  const { player } = usePlayerContext();
  const { appState } = useAppState();
  const isChatWindowOpenRef = useRef(false);
  const [isChatWindowOpen, setIsChatWindowOpen] = useState(false);
  const [conversation, setConversation] = useState<Conversation | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [chatClient, setChatClient] = useState<Client>();

  const connect = useCallback(
    (token: string) => {
      if (!chatClient) {
        let conversationOptions;
        if (process.env.REACT_APP_TWILIO_ENVIRONMENT) {
          conversationOptions = { region: `${process.env.REACT_APP_TWILIO_ENVIRONMENT}-us1` };
        }
        Client.create(token, conversationOptions)
          .then(client => {
            //@ts-ignore
            window.chatClient = client;
            setChatClient(client);
          })
          .catch(e => {
            console.error(e);
            onError(new Error("There was a problem connecting to Twilio's conversation service."));
          });
      }
    },
    [onError, chatClient]
  );

  const disconnect = useCallback(() => {
    setChatClient(undefined);
    chatClient?.shutdown();
  }, [chatClient]);

  useEffect(() => {
    if (conversation) {
      let isPaging = true;
      setMessages([]);

      const pageHandler = (paginator: any) => {
        setMessages((oldMessages: any) => {
          if (oldMessages.length > 0) {
            return [...oldMessages, ...paginator.items];
          } else {
            return [...paginator.items];
          }
        });

        return paginator.hasNextPage ? paginator.nextPage().then(pageHandler) : null;
      };

      const handleMessageAdded = (message: Message) => {
        if (!isPaging) {
          setMessages(oldMessages => [...oldMessages, message]);
        }
      };

      const handleMessageUpdated = () => {
        const updatedMessages = [];

        setMessages(oldMessages => {
          oldMessages.forEach(oldMessage => {
            updatedMessages.push(oldMessage);
            // instant feedback buggy with this method
          });

          return [...oldMessages];
        });
      };

      conversation.getMessages().then(() => {
        conversation
          .getMessages(100, 0, 'forward')
          .then(pageHandler)
          .finally(() => {
            isPaging = false;
          })
          .catch(error => {
            console.error('Map conversation messages failed', error);
          });
      });

      conversation.on('messageAdded', handleMessageAdded);
      conversation.on('messageUpdated', handleMessageUpdated);
      return () => {
        conversation.off('messageAdded', handleMessageAdded);
        conversation.off('messageUpdated', handleMessageUpdated);
      };
    }
  }, [conversation]);

  useEffect(() => {
    // If the chat window is closed and there are new messages, set hasUnreadMessages to true
    if (!isChatWindowOpenRef.current && messages.length) {
      setHasUnreadMessages(true);
    }
  }, [messages]);

  useEffect(() => {
    isChatWindowOpenRef.current = isChatWindowOpen;
    if (isChatWindowOpen) setHasUnreadMessages(false);
  }, [isChatWindowOpen]);

  useEffect(() => {
    if ((room || player) && chatClient && appState.roomSid) {
      setConversation(null);

      chatClient
        .getConversationByUniqueName(appState.roomSid)
        .then(newConversation => {
          //@ts-ignore
          window.chatConversation = newConversation;
          setConversation(newConversation);
        })
        .catch(e => {
          console.error(e);
          onError(new Error('There was a problem getting the Conversation associated with this room.'));
        });
    }
  }, [room, player, chatClient, appState.roomSid, onError]);

  return (
    <ChatContext.Provider
      value={{ isChatWindowOpen, setIsChatWindowOpen, connect, disconnect, hasUnreadMessages, messages, conversation }}
    >
      {children}
    </ChatContext.Provider>
  );
};
