import React from 'react';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '../../icons/ErrorIcon';
import { IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import MUISnackbar from '@material-ui/core/Snackbar';
import WarningIcon from '../../icons/WarningIcon';
import InfoIcon from '../../icons/InfoIcon';
import CrownIcon from '../../icons/CrownIcon';
import AlertIconTriangle from '../../icons/AlertIconTriangle';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '400px',
    minHeight: '50px',
    background: 'white',
    padding: '1em',
    borderRadius: '3px',
    boxShadow: '0 12px 24px 4px rgba(40,42,43,0.2)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  contentContainer: {
    display: 'flex',
    lineHeight: 1.8,
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    hyphens: 'auto',
  },
  iconContainer: {
    display: 'flex',
    padding: '0 1.3em 0 0.3em',
    transform: 'translateY(3px)',
  },
  iconAnnouncementContainer: {
    display: 'flex',
    padding: '0 3em 0 2em',
    alignItems: 'center',
  },
  headline: {
    fontWeight: 'bold',
  },
  error: {
    borderLeft: '4px solid #D61F1F',
  },
  warning: {
    borderLeft: '4px solid #E46216',
  },
  info: {
    borderLeft: '4px solid #0263e0',
  },
  announcement: {
    backgroundColor: '#FFCC00',
    borderLeft: '4px solid black',
    width: '100%',
    '& $contentContainer': {
      padding: '0 20px 0 0',
    },
    '& .MuiTypography-body1': {
      fontSize: '24px',
    },
  },
}));

interface SnackbarImplProps {
  headline: string;
  message: string | React.ReactNode | any;
  variant?: 'error' | 'warning' | 'info' | 'announcement' | 'announcement-tko';
  handleClose?: () => void;
}

export function SnackbarImpl({ headline, message, variant, handleClose }: SnackbarImplProps) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.container, {
        [classes.error]: variant === 'error',
        [classes.warning]: variant === 'warning',
        [classes.info]: variant === 'info',
        [classes.announcement]: variant === 'announcement' || variant === 'announcement-tko',
      })}
    >
      <div className={classes.contentContainer}>
        <div
          className={
            variant === 'announcement' || variant === 'announcement-tko'
              ? classes.iconAnnouncementContainer
              : classes.iconContainer
          }
        >
          {variant === 'warning' && <WarningIcon />}
          {variant === 'error' && <ErrorIcon />}
          {variant === 'info' && <InfoIcon />}
          {variant === 'announcement-tko' && <CrownIcon />}
          {variant === 'announcement' && <AlertIconTriangle />}
        </div>
        <div style={{ display: 'flex', flexFlow: 'column' }}>
          <Typography variant="body1" className={classes.headline} component="span">
            {headline}
          </Typography>
          <Typography variant="body1" dangerouslySetInnerHTML={{ __html: message }}></Typography>
        </div>
      </div>
      <div>
        {handleClose && (
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      </div>
    </div>
  );
}

interface SnackbarProps {
  headline: string;
  message: string | React.ReactNode;
  variant?: 'error' | 'warning' | 'info' | 'announcement' | 'announcement-tko';
  open: boolean;
  handleClose?: () => void;
}

export default function Snackbar({ headline, message, variant, open, handleClose }: SnackbarProps) {
  const handleOnClose = (_: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    handleClose?.();
  };

  return (
    <MUISnackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={handleOnClose}
      autoHideDuration={10000}
    >
      <SnackbarImpl headline={headline} message={message} variant={variant} handleClose={handleClose} />
    </MUISnackbar>
  );
}
