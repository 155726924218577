import React, { useState, useRef } from 'react';
import { Button, Menu as MenuContainer, MenuItem, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import ConfirmEventDialog from '../../ConfirmEventDialog/ConfirmEventDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: 'white',
      '&:hover': {
        background: '#600101',
      },
    },
  })
);

export default function LeaveEventButton(props: { buttonClassName?: string }) {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [menuOption, setMenuOption] = useState<string>();

  const { room } = useVideoContext();

  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleClose = () => {
    setOpen(false);
    setMenuOpen(false);
  };
  return (
    <>
      <Button onClick={() => setMenuOpen(isOpen => !isOpen)} ref={anchorRef} className={classes.button}>
        Leave Event
        <ExpandMoreIcon />
      </Button>
      <MenuContainer
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={() => {
            setMenuOption('switchToViewer');
            setOpen(true);
            setMenuOpen(false);
          }}
        >
          <Typography variant="body1">View Event</Typography>
        </MenuItem>

        <MenuItem
          onClick={() => {
            setMenuOption('leaveEvent');
            setOpen(true);
            setMenuOpen(false);
          }}
        >
          <Typography variant="body1">Leave Event</Typography>
        </MenuItem>
      </MenuContainer>
      <ConfirmEventDialog
        id="leaveEvent-menu"
        title={menuOption === 'leaveEvent' ? `Leave Event?` : `View Event?`}
        content={
          menuOption === 'leaveEvent'
            ? `Are you sure you want to leave the <strong>${
                room!.name.split(' | ')[0]
              }</strong> event? The event will continue, you can re-join at any time.`
            : `Are you sure you want to leave and re-join the <strong>${
                room!.name.split(' | ')[0]
              }</strong> event as a viewer? You will still have moderation privileges and can re-join at any time.`
        }
        confirmtext={menuOption === 'leaveEvent' ? `Leave Event` : `Leave and View Event`}
        keepMounted
        open={open}
        onClose={handleClose}
        action={menuOption}
      />
    </>
  );
}
