import React, { createContext, useEffect, useRef, useState } from 'react';
import { SyncClient, SyncDocument, SyncMap } from 'twilio-sync';
import usePlayerContext from '../../hooks/usePlayerContext/usePlayerContext';
import { useAppState } from '../../state';

type SyncContextType = {
  connect: (token: string) => void;
  disconnect: () => void;
  registerUserDocument: (userDocumentName: string) => Promise<void>;
  raisedHandsMap: SyncMap | undefined;
  speakersMap: SyncMap | undefined;
  moderatorsMap: SyncMap | undefined;
  viewersMap: SyncMap | undefined;
  userDocument: SyncDocument | undefined;
  userDocumentNotes: SyncDocument | undefined;
  chatBansMap: SyncMap | undefined;
  eventBansMap: SyncMap | undefined;
  globalAppStateMap: SyncMap | undefined;
  registerSyncMaps: (syncObjectNames: SyncObjectNames) => void;
};

type SyncObjectNames = {
  speakers_map: string;
  moderators_map: string;
  viewers_map: string;
  raised_hands_map: string;
  chat_bans_map: string;
  event_bans_map: string;
  global_app_state_map: string;
};

export const SyncContext = createContext<SyncContextType>(null!);

export const SyncProvider: React.FC = ({ children }) => {
  const { appDispatch } = useAppState();
  const { player } = usePlayerContext();
  const [raisedHandsMap, setRaisedHandsMap] = useState<SyncMap>();
  const [speakersMap, setSpeakersMap] = useState<SyncMap>();
  const [moderatorsMap, setModeratorsMap] = useState<SyncMap>();
  const [viewersMap, setViewersMap] = useState<SyncMap>();
  const [userDocument, setUserDocument] = useState<SyncDocument>();
  const [userDocumentNotes, setUserDocumentNotes] = useState<SyncDocument>();
  const [chatBansMap, setChatBansMap] = useState<SyncMap>();
  const [eventBansMap, setEventBansMap] = useState<SyncMap>();
  const [globalAppStateMap, setGlobalAppStateMap] = useState<SyncMap>();

  const syncClientRef = useRef<SyncClient>();

  function connect(token: string) {
    let syncOptions;
    if (process.env.REACT_APP_TWILIO_ENVIRONMENT) {
      syncOptions = { region: `${process.env.REACT_APP_TWILIO_ENVIRONMENT}-us1` };
    }
    syncClientRef.current = new SyncClient(token, syncOptions);
  }

  function disconnect() {
    if (syncClientRef.current) {
      setRaisedHandsMap(undefined);
      setSpeakersMap(undefined);
      setModeratorsMap(undefined);
      setViewersMap(undefined);
      setUserDocument(undefined);
      setUserDocumentNotes(undefined);
      setGlobalAppStateMap(undefined);
      syncClientRef.current.shutdown();
    }
  }

  function registerUserDocument(userDocumentName: string) {
    if (userDocumentName === 'app_notepad') {
      return syncClientRef.current!.document(userDocumentName).then(document => setUserDocumentNotes(document));
    } else {
      return syncClientRef.current!.document(userDocumentName).then(document => setUserDocument(document));
    }
  }

  function registerSyncMaps({
    speakers_map,
    moderators_map,
    viewers_map,
    raised_hands_map,
    chat_bans_map,
    event_bans_map,
    global_app_state_map,
  }: SyncObjectNames) {
    const syncClient = syncClientRef.current!;
    syncClient.map(raised_hands_map).then(map => setRaisedHandsMap(map));
    syncClient.map(speakers_map).then(map => setSpeakersMap(map));
    syncClient.map(moderators_map).then(map => setModeratorsMap(map));
    syncClient.map(viewers_map).then(map => setViewersMap(map));
    syncClient.map(chat_bans_map).then(map => setChatBansMap(map));
    syncClient.map(event_bans_map).then(map => setEventBansMap(map));
    syncClient.map(global_app_state_map).then(map => setGlobalAppStateMap(map));
  }

  useEffect(() => {
    // The user can only accept a speaker invite when they are a viewer (when there is a player)
    if (userDocument && player) {
      const handleUpdate = (update: any) => {
        if (typeof update.data.speaker_invite !== 'undefined') {
          appDispatch({ type: 'set-has-speaker-invite', hasSpeakerInvite: update.data.speaker_invite });
        }
      };

      userDocument.on('updated', handleUpdate);
      return () => {
        userDocument.off('updated', handleUpdate);
      };
    }
  }, [userDocument, player, appDispatch]);

  return (
    <SyncContext.Provider
      value={{
        connect,
        disconnect,
        registerUserDocument,
        raisedHandsMap,
        speakersMap,
        moderatorsMap,
        viewersMap,
        chatBansMap,
        eventBansMap,
        globalAppStateMap,
        userDocument,
        userDocumentNotes,
        registerSyncMaps,
      }}
    >
      {children}
    </SyncContext.Provider>
  );
};
