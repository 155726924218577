import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import ChatInput from './ChatInput/ChatInput';
import { Message } from '@twilio/conversations';
import MessageList from './MessageList/MessageList';
import useChatContext from '../../hooks/useChatContext/useChatContext';

// In this component, we are toggling the visibility of the ChatWindow with CSS instead of
// conditionally rendering the component in the DOM. This is done so that the ChatWindow is
// not unmounted while a file upload is in progress.

interface ChatWindowProps {
  isChatEnabled?: boolean;
  isAttachmentEnabled?: boolean;
  isAnnouncemenTab: boolean;
  appState?: any;
  chatBans: any;
  eventBans: any;
  globalAppState: any;
  globalAppStateMap: any;
  pubcode: string;
}

export default function ChatWindow({
  isAttachmentEnabled,
  isChatEnabled,
  appState,
  isAnnouncemenTab,
  chatBans,
  eventBans,
  globalAppState,
  globalAppStateMap,
  pubcode,
}: ChatWindowProps) {
  const { isChatWindowOpen, messages, conversation } = useChatContext();
  const [announcements, setAnnouncements] = useState<Message[]>([]);
  const [isGettingAnnouncements, setIsGettingAnnouncements] = useState(false);

  function getAnnouncements() {
    // no way to get a list?
    globalAppState.announcements?.forEach((item: any) => {
      conversation?.getMessages(1, item).then(res => {
        // possible bug when toggle chat tabs quickly
        if (isAnnouncemenTab) {
          setAnnouncements(oldAnnouncements => [...oldAnnouncements, res.items[0]]);
        }
      });
    });
  }

  // TODO: create setAnnouncements() to userDocument

  if (!conversation) return null;

  if (isAnnouncemenTab && !isGettingAnnouncements) {
    setIsGettingAnnouncements(true);
    getAnnouncements();
  }

  return (
    <>
      {appState.participantType === 'host' || appState.participantType === 'moderator' || isChatEnabled ? (
        <>
          <MessageList
            conversation={conversation}
            messages={isAnnouncemenTab ? announcements : messages}
            canModerate={appState.participantType === 'host' || appState.participantType === 'moderator'}
            isAnnouncemenTab={isAnnouncemenTab}
            chatBans={chatBans}
            eventBans={eventBans}
            globalAppState={globalAppState}
            setAnnouncements={setAnnouncements}
            pubcode={pubcode}
          />
          {!isAnnouncemenTab && (
            <ChatInput
              conversation={conversation!}
              isChatWindowOpen={isChatWindowOpen}
              isAttachmentEnabled={isAttachmentEnabled}
              appState={appState}
              globalAppStateMap={globalAppStateMap}
              pubcode={pubcode}
            />
          )}
        </>
      ) : (
        <Box style={{ padding: '20px' }}>
          <Typography variant="body1" align="center">
            Chat is disabled
          </Typography>
        </Box>
      )}
    </>
  );
}
