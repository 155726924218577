import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Tab, Tabs, Box, IconButton, Button, Tooltip, Badge, Typography } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import RefreshIcon from '@material-ui/icons//Refresh';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import Room from '../Room/Room';
import Player from '../Player/Player';
import MenuBar from '../MenuBar/MenuBar';
import PlayerMenuBar from '../Player/PlayerMenuBar/PlayerMenuBar';
import TabsWindow from '../TabsWindow/TabsWindow';
import Notes from '../NotePad/Notes';
import Tiny from '../NotePad/Tiny';
import { isMobile, useWindowDimensions } from '../../utils';
import useChatContext from '../../hooks/useChatContext/useChatContext';
import usePlayerContext from '../../hooks/usePlayerContext/usePlayerContext';
import { useGlobalAppStateMap } from '../../hooks/useGlobalAppState/useGlobalAppState';
import { useChatBansMap } from '../../hooks/useChatBansMap/useChatBansMap';
import { useEventBansMap } from '../../hooks/useEventBansMap/useEventBansMap';
import { useSpeakersMap } from '../../hooks/useSpeakersMap/useSpeakersMap';
import useSyncContext from '../../hooks/useSyncContext/useSyncContext';
import { useAppState } from '../../state';
import axios from 'axios';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
      className={classes.fullHeightTabPanel}
      role="tabpanel"
      style={{ display: value !== index ? 'none' : 'flex' }}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box style={{ display: 'flex', flex: 1, flexFlow: 'column', position: 'relative' }}>{children}</Box>
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'wrap',
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  fullHeightTabPanel: {
    display: 'flex',
    flex: '1 1 100%',
    height: `calc(100% - ${theme.tabHeaderHeight}px)`,
  },
  fullScreenContainer: {
    display: 'flex',
    flexFlow: 'wrap',
    flex: 1,
    backgroundColor: 'white',
    height: `calc(100% - ${theme.footerHeight - 11}px)`,
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'column',
      height: `calc(100% - ${theme.mobileFooterHeightViewer + 5}px)`,
    },
    // '&.isFullscreen': {
    //   '& $mainTabContainer': {
    //     height: `calc(100% - ${theme.footerHeight}px)`,
    //   },
    //   '& .tabWindowContainer': {
    //     height: `calc(100% - ${theme.footerHeight}px)`,
    //   },
    // },
  },
  mainTabContainer: {
    display: 'flex',
    flexFlow: 'wrap',
    width: `100%`,
    height: `100%`,
  },
  desktopLogo: {
    height: theme.headerLogoHeight,
    marginLeft: '8px',
    marginTop: '8px',
  },
  mobileLogo: {
    height: theme.mobileHeaderLogoHeight,
    marginLeft: '8px',
    marginTop: '8px',
  },
  rightDrawerOpen: {
    '& $mainTabContainer': {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${theme.rightDrawerWidth}px)`,
      },
      [theme.breakpoints.down('sm')]: {
        flex: 1,
      },
    },
  },
  logoContainer: {
    width: '100%',
    padding: '0 0 8px 0',
    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    zIndex: 10,
  },
  notesContainer: {
    background: 'rgba(0,0,0,0.04)',
    height: '100%',
    padding: '1rem',
  },
  notesFrameFail: {
    justifyContent: 'center',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
  },
  notesFrame: {
    width: '100%',
    height: '100%',
    background: 'white',
    border: '0.1em solid #CACDD8',
    padding: '1rem',
    overflow: 'auto',
  },
  refreshButton: {
    margin: '16px',
  },
  //overwrite & use root styles
  lockedIconLabel: {
    minHeight: theme.tabHeaderHeight,
    paddingTop: '6px',
  },
  lockedIcon: {
    flexDirection: 'row-reverse',
    '& svg': {
      marginLeft: '0.2em',
      marginBottom: '3px !important',
      width: '0.675em',
      height: '0.675em',
    },
  },
  buttonActive: {
    opacity: 1,
    margin: '3px 6px',
    padding: '6px 9px',
    color: theme.brand,
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04)',
    },
  },
  button: {
    opacity: 0.7,
    margin: '3px 6px',
    padding: '6px 9px',
    color: 'rgb(40, 42, 43)',
    '&:hover': {
      opacity: 1,
      background: 'rgba(0, 0, 0, 0.04)',
    },
  },
  landscape: {
    '& .hide': {
      display: 'none',
    },
    '& $fullScreenContainer': {
      height: '100%',
      flexFlow: 'wrap',
    },
    '& .tabWindowContainer': {
      minWidth: '33.333%',
      maxWidth: '33.333%',
      height: '100%',
    },
  },
}));

export default function TabsScreen(props: { hasPlayer: boolean }) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [isTabsLocked, setIsTabsLocked] = useState(false);
  const [isGettingImgSrc, setIsGettingImgSrc] = useState(false);
  const [logoImgSource, setLogoImgSource] = useState<string | undefined>();
  const [externalSlug, setExternalSlug] = useState<string | undefined>();
  const [isGettingRules, setIsGettingRules] = useState(false);
  const [rulesContent, setRulesContent] = useState<string | undefined>();
  const { disconnect } = usePlayerContext();
  const { isChatWindowOpen } = useChatContext();
  const { appState, user, appDispatch } = useAppState();
  const globalAppState = useGlobalAppStateMap();
  const { globalAppStateMap } = useSyncContext();
  const chatBans = useChatBansMap();
  const eventBans = useEventBansMap();
  const { host, speakers } = useSpeakersMap();
  const { height, width } = useWindowDimensions();
  const isPortrait = (height ?? 0) > (width ?? 0);

  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    // host force viewers to change tab view
    if (appState.participantType === 'host' || appState.participantType === 'moderator') {
      if (isTabsLocked) {
        globalAppStateMap?.getItems({ pageSize: 100 }).then(items => {
          globalAppStateMap.update('globalAppState', {
            ...items.items[0].data,
            lockTab: newValue,
            isTabsLocked: isTabsLocked,
          });
        });
      }

      setValue(newValue);
    } else {
      if (isTabsLocked) {
        setValue(globalAppState.lockTab);
        appDispatch({ type: 'set-active-tab', activeTab: globalAppState.lockTab });
      } else {
        setValue(newValue);
        appDispatch({ type: 'set-active-tab', activeTab: newValue });
      }

      if (newValue === 2 || (isTabsLocked && globalAppState.lockTab === 2)) {
        appDispatch({ type: 'set-notes-update', hasNotesUpdate: false });
      }
    }
  };

  const handleTabLock = () => {
    globalAppStateMap?.getItems({ pageSize: 100 }).then(items => {
      globalAppStateMap.update('globalAppState', {
        ...items.items[0].data,
        lockTab: value,
        isTabsLocked: !isTabsLocked,
      });
    });

    setIsTabsLocked(!isTabsLocked);
  };

  useEffect(() => {
    if (appState.participantType !== 'host') {
      globalAppStateMap?.getItems({ pageSize: 100 }).then(items => {
        if (typeof (items.items[0].data as any).isTabsLocked !== 'undefined') {
          setIsTabsLocked((items.items[0].data as any).isTabsLocked);
        }

        if (typeof (items.items[0].data as any).lockTab !== 'undefined') {
          if ((items.items[0].data as any).isTabsLocked) {
            setValue((items.items[0].data as any).lockTab);
            appDispatch({ type: 'set-active-tab', activeTab: (items.items[0].data as any).lockTab });
          }

          if ((items.items[0].data as any).lockTab === 2) {
            appDispatch({ type: 'set-notes-update', hasNotesUpdate: false });
          }
        } else {
          setValue(0);
          appDispatch({ type: 'set-active-tab', activeTab: 0 });
        }
      });
    }
  }, [globalAppStateMap, globalAppState, appState.participantType, appDispatch]);

  async function getSubscriptionsList() {
    return await axios.post(
      `https://${appState.originDomain === 'mam' ? 'moneyandmarkets' : 'banyanhill'}.com/graphql`,
      {
        query: `
        {
          pages(
            where: {categoryName: "Subscriptions", orderby: {field: TITLE, order: ASC}, status: PUBLISH}
            first: 60
          ) {
            edges {
              node {
                featuredImage {
                  node {
                    sourceUrl
                  }
                }
                pubcode
                slug
                title(format: RENDERED)
              }
            }
          }
        }
      `,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  let pubcode = appState.eventName.split(' | ');

  if (!props.hasPlayer) {
    pubcode[1] = pubcode[1].split(',')[0];
  } else {
    pubcode = pubcode[1].indexOf(',') === -1 ? [pubcode[1]] : pubcode[1].split(',');
  }

  for (let i = 0; i < pubcode.length; i++) {
    if (typeof user?.pubcodes !== 'undefined' && ((user.pubcodes as unknown) as string).includes(pubcode[i])) {
      pubcode[1] = pubcode[i];
    }
  }

  if (!logoImgSource && !isGettingImgSrc) {
    setIsGettingImgSrc(true);
    getSubscriptionsList().then(list => {
      (list.data as any)?.data.pages.edges.forEach((subscription: any) => {
        if (subscription.node.pubcode === pubcode[1]) {
          setExternalSlug(subscription.node.slug);
          setLogoImgSource(subscription.node.featuredImage.node.sourceUrl);
          setIsGettingImgSrc(false);
        }
      });
    });
  }

  useEffect(() => {
    if (!externalSlug) return;

    async function getRulesCopy() {
      return await axios.post(
        `https://${appState.originDomain === 'mam' ? 'moneyandmarkets' : 'banyanhill'}.com/graphql`,
        {
          query: `
          {
            externals(where: {name: "${externalSlug}", status: PUBLISH}) {
              edges {
                node {
                  slug
                  children(where: {name: "rules"}) {
                    edges {
                      node {
                          ... on External {
                          content
                          databaseId
                          date
                          externalSettings {
                            headerBlurb
                          }
                          slug
                          title
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    }

    if (!rulesContent && !isGettingRules) {
      setIsGettingRules(true);
      getRulesCopy().then(rules => {
        if ((rules.data as any).data.externals.edges[0].node.children.edges.length > 0) {
          setRulesContent((rules.data as any)?.data.externals.edges[0].node.children.edges[0].node.content);
        } else {
          setRulesContent('none');
        }

        setIsGettingRules(false);
      });
    }
  }, [externalSlug, rulesContent, isGettingRules, appState.originDomain]);

  return (
    <div
      className={clsx(classes.root, {
        [classes.rightDrawerOpen]:
          (isChatWindowOpen && globalAppState.isChatEnabled) || appState.isParticipantWindowOpen,
        [classes.landscape]: isMobile && !isPortrait,
      })}
    >
      <div className={clsx(classes.logoContainer, { hide: isMobile && !isPortrait })}>
        {logoImgSource && (
          <img className={isMobile ? classes.mobileLogo : classes.desktopLogo} src={logoImgSource} alt="logo" />
        )}
      </div>
      <div id="fullscreenContainer" className={classes.fullScreenContainer}>
        <div className={classes.mainTabContainer}>
          <div style={{ display: 'flex', flexFlow: 'wrap' }}>
            <Tabs style={{ color: 'black' }} value={value} onChange={handleChange} aria-label="App Tabs" id="mainTabs">
              <Tab
                label="Live Video"
                classes={{
                  labelIcon: classes.lockedIconLabel,
                  wrapper: classes.lockedIcon,
                }}
                disabled={appState.participantType !== 'host' && isTabsLocked}
                icon={appState.participantType !== 'host' && isTabsLocked ? <LockOutlinedIcon /> : ''}
                {...a11yProps(0)}
              />
              <Tab
                label="Rules"
                classes={{
                  labelIcon: classes.lockedIconLabel,
                  wrapper: classes.lockedIcon,
                }}
                disabled={appState.participantType !== 'host' && isTabsLocked}
                icon={appState.participantType !== 'host' && isTabsLocked ? <LockOutlinedIcon /> : ''}
                {...a11yProps(1)}
              />
              <Tab
                label={
                  appState.participantType !== 'host' &&
                  appState.hasNotesUpdate &&
                  (value !== 2 || (isTabsLocked && globalAppState.lockTab !== 2)) ? (
                    <Badge color="secondary" variant="dot">
                      Notes
                    </Badge>
                  ) : (
                    `Notes`
                  )
                }
                classes={{
                  labelIcon: classes.lockedIconLabel,
                  wrapper: classes.lockedIcon,
                }}
                disabled={appState.participantType !== 'host' && isTabsLocked}
                icon={appState.participantType !== 'host' && isTabsLocked ? <LockOutlinedIcon /> : ''}
                {...a11yProps(2)}
              />
            </Tabs>
            {!props.hasPlayer && (
              <Tooltip title={`${isTabsLocked ? 'Unlock' : 'Lock'} Viewer Tabs`} placement="top">
                <IconButton
                  onClick={handleTabLock}
                  ref={anchorRef}
                  className={isTabsLocked ? classes.buttonActive : classes.button}
                >
                  {isTabsLocked ? <LockOutlinedIcon /> : <LockOpenOutlinedIcon />}
                </IconButton>
              </Tooltip>
            )}
          </div>
          <TabPanel value={value} index={0}>
            {!props.hasPlayer ? <Room host={host} /> : <Player eventBans={eventBans} />}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {rulesContent && rulesContent !== 'none' ? (
              // TODO: use Notes component if possible
              <div className={classes.notesContainer}>
                <div
                  className={classes.notesFrame}
                  id="notepad"
                  dangerouslySetInnerHTML={{ __html: rulesContent }}
                ></div>
              </div>
            ) : (
              <div className={classes.notesContainer}>
                <div className={clsx(classes.notesFrame, classes.notesFrameFail)} id="notepad">
                  <Typography variant="body1" align="center">
                    No content found.
                  </Typography>
                  {props.hasPlayer ? (
                    <Button
                      size="large"
                      color="primary"
                      variant="contained"
                      startIcon={<RefreshIcon />}
                      className={classes.refreshButton}
                      onClick={() => {
                        setRulesContent('none');
                      }}
                    >
                      {' '}
                      REFRESH
                    </Button>
                  ) : (
                    <Typography variant="body1" align="center">
                      Content not found on{' '}
                      {`https://${
                        appState.originDomain === 'mam' ? 'moneyandmarkets' : 'banyanhill'
                      }.com/externals/${externalSlug}/rules`}
                    </Typography>
                  )}
                </div>
              </div>
            )}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {!props.hasPlayer ? <Tiny padId="NotesPad" preContent="" /> : <Notes padId="NotesPad" />}
          </TabPanel>
        </div>
        <TabsWindow
          globalAppState={globalAppState}
          globalAppStateMap={globalAppStateMap}
          chatBans={chatBans}
          eventBans={eventBans}
          host={host}
          speakers={speakers}
          isPortrait={isPortrait}
          pubcode={pubcode[1]}
          externalSlug={externalSlug}
        />
        {!props.hasPlayer ? (
          <MenuBar globalAppState={globalAppState} />
        ) : (
          <PlayerMenuBar globalAppState={globalAppState} roomName={appState.eventName} disconnect={disconnect} />
        )}
      </div>
    </div>
  );
}
