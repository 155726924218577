import React, { useState, useRef } from 'react';
import { Button, Menu, MenuItem, MenuList, Divider } from '@material-ui/core';
import SpeakerMenuIcon from '../../../../icons/SpeakerMenuIcon';
import useSyncContext from '../../../../hooks/useSyncContext/useSyncContext';
import { useEnqueueSnackbar } from '../../../../hooks/useSnackbar/useSnackbar';
// import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';
// import { sendChatInvite } from '../../../../state/api/api';

export function MessageMenu({
  blockedBodyText,
  message,
  chatBans,
  eventBans,
  canModerate,
  appState,
}: {
  blockedBodyText: string;
  message: { [key: string]: any };
  chatBans: any;
  eventBans: any;
  canModerate: boolean;
  appState: any;
}) {
  const [menuOpen, setMenuOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  // const { room } = useVideoContext();
  const { chatBansMap, eventBansMap, userDocument } = useSyncContext();
  const enqueueSnackbar = useEnqueueSnackbar();

  async function handleRemoveFromChat() {
    setMenuOpen(false);
    chatBansMap
      ?.get(message.author)
      .then(() => {
        chatBansMap?.remove(message.author);
      })
      .catch(error => {
        // author is not on map, add them
        chatBansMap?.set(message.author, {});
      });
  }
  async function handleRemoveMessage() {
    setMenuOpen(false);
    if (message.attributes.block_chat === true) {
      message.updateAttributes({
        ...message.attributes,
        block_chat: false,
      });
    } else {
      message.updateAttributes({
        ...message.attributes,
        block_chat: true,
      });
    }
  }
  async function handleRemoveSelf() {
    setMenuOpen(false);
    if (message.attributes.retract_chat === true) {
      message.updateAttributes({});
    } else {
      message.updateAttributes({
        ...message.attributes,
        retract_chat: true,
      });
    }
  }
  async function handleRemoveFromEvent() {
    setMenuOpen(false);
    eventBansMap
      ?.get(message.author)
      .then(() => {
        eventBansMap?.remove(message.author);
      })
      .catch(error => {
        // author is not on map, add them
        eventBansMap?.set(message.author, {});
      });
  }
  // async function handlePrivateMessage() {
  //   sendChatInvite(`${room?.sid}-${message.author}`, room?.localParticipant.identity);
  // }

  const getFormattedAuthor = (author: string) => {
    let authorArray = author?.split(' ');

    if (appState.participantType === 'host' || appState.participantType === 'moderator') return author;

    if (authorArray.length > 1) {
      return `${authorArray[0]} ${authorArray[authorArray.length - 1].charAt(0)}.`;
    } else {
      return author;
    }
  };

  async function handleBlock() {
    // add clicked user to participant's personal block list
    setMenuOpen(false);
    let currentBlocks = (userDocument?.data as any)?.block_participants || [];
    if (currentBlocks && currentBlocks?.includes(message.author)) {
      currentBlocks = currentBlocks.filter((participant: string) => participant !== message.author);

      message.updateAttributes({
        ...message.attributes,
        block_chat_participant: false,
      });

      enqueueSnackbar({
        headline: '',
        message: `${getFormattedAuthor(message.author)} has been unblocked. `,
        variant: 'info',
      });
    } else {
      currentBlocks.push(message.author);

      message.updateAttributes({
        ...message.attributes,
        block_chat_participant: true,
      });

      enqueueSnackbar({
        headline: '',
        message: `${getFormattedAuthor(message.author)} has been blocked. You will no longer see messages from them.`,
        variant: 'info',
      });
    }

    userDocument?.update({
      block_participants: currentBlocks,
    });
  }

  async function handleCopy() {
    setMenuOpen(false);

    if (blockedBodyText) {
      enqueueSnackbar({
        headline: '',
        message: `Cannot copy message.`,
        variant: 'warning',
      });

      return;
    }

    navigator.clipboard.writeText(message.body);
  }

  return message.attributes.retract_chat !== true ? (
    <>
      <Button onClick={() => setMenuOpen(isOpen => !isOpen)} ref={anchorRef}>
        <SpeakerMenuIcon />
      </Button>
      <Menu
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuList dense>
          {canModerate && appState.participantName !== message.author ? (
            [
              // [<MenuItem onClick={handlePrivateMessage}>Send Private Message</MenuItem>],
              [
                <MenuItem onClick={handleRemoveMessage}>
                  {message.attributes.block_chat === true ? `Display` : `Block`} Message
                </MenuItem>,
              ],
              [<Divider />],
              [
                <MenuItem onClick={handleRemoveFromChat} style={{ color: 'red' }}>
                  {chatBans.includes(message.author) ? `Unmute` : `Mute`} in Chat
                </MenuItem>,
              ],
              [
                <MenuItem onClick={handleRemoveFromEvent} style={{ color: 'red' }}>
                  {eventBans.includes(message.author) ? `Unban` : `Ban`}
                </MenuItem>,
              ],
            ]
          ) : appState.participantName === message.author ? (
            <MenuItem onClick={handleRemoveSelf}>Remove</MenuItem>
          ) : (
            !canModerate && [
              [
                <MenuItem onClick={handleBlock}>
                  {(userDocument?.data as any)?.block_participants?.includes(message.author) ? `Unblock` : `Block`}
                </MenuItem>,
              ],
              [<MenuItem onClick={handleCopy}>Copy</MenuItem>],
            ]
          )}
        </MenuList>
      </Menu>
    </>
  ) : (
    <></>
  );
}
