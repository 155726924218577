import React, { useEffect, useState, useMemo, useCallback } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';
import useSyncContext from '../../hooks/useSyncContext/useSyncContext';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { useGlobalAppStateMap } from '../../hooks/useGlobalAppState/useGlobalAppState';
import {
  EC2Client,
  ModifyInstanceAttributeCommand,
  StartInstancesCommand,
  StopInstancesCommand,
  DescribeInstancesCommand,
} from '@aws-sdk/client-ec2';
import { IvsClient, GetStreamCommand } from '@aws-sdk/client-ivs';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: 'white',
      marginLeft: '1em',
      '&:hover': {
        background: '#600101',
      },
    },
  })
);

export interface ConfirmBroadcastDialogProps {
  id: string;
  title: string;
  content: string;
  confirmtext: string;
  keepMounted: boolean;
  open: boolean;
  onClose: (value?: string) => void;
  action: any;
}

export default function ConfirmBroadcastDialog(props: ConfirmBroadcastDialogProps) {
  const classes = useStyles();
  const { room } = useVideoContext();
  const { globalAppStateMap } = useSyncContext();
  const globalAppState = useGlobalAppStateMap();
  const { onClose, open, ...other } = props;
  const [broadcastState, setBroadcastState] = useState(globalAppState.instance?.id ? 'init' : 'stop');

  const handleCancel = () => {
    onClose();
  };

  const handleOk = (action: string) => {
    switch (action) {
      case 'toggleRecord': {
        toggleRecord();
        break;
      }
    }
  };

  const updateGlobalAppState = useCallback(
    (instanceObj: any) => {
      globalAppStateMap?.getItems({ pageSize: 100 }).then(items => {
        globalAppStateMap.update('globalAppState', {
          ...items.items[0].data,
          instance: instanceObj,
        });
      });
    },
    [globalAppStateMap]
  );

  // Create EC2 service object
  const ec2 = useMemo(
    () =>
      new EC2Client({
        credentials: {
          accessKeyId: 'AKIAY4CYQBB3NT5YBSML',
          secretAccessKey: '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',
        },
        region: 'us-east-1',
        apiVersion: '2016-11-15',
      }),
    []
  );

  const ivs = useMemo(
    () =>
      new IvsClient({
        credentials: {
          accessKeyId: 'AKIAY4CYQBB3NT5YBSML',
          secretAccessKey: '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',
        },
        region: 'us-east-1',
      }),
    []
  );

  const AWSInstances = useMemo(
    () => [
      // trade-room-1.banyanhill.com
      {
        InstanceId: 'i-021571a61b285fff1',
        channelArn: 'arn:aws:ivs:us-east-1:610070956150:channel/5APtrqTbVXiO',
        rtmpUrl:
          'rtmps://0de9950180a8.global-contribute.live-video.net:443/app/sk_us-east-1_jOHIMCl70JrJ_wA46XxBV3DGPr3ZzDyojRKAsu5o1ie',
        playbackUrl:
          'https://0de9950180a8.us-east-1.playback.live-video.net/api/video/v1/us-east-1.610070956150.channel.5APtrqTbVXiO.m3u8',
      },
      // trade-room-2.banyanhill.com
      {
        InstanceId: 'i-06bb0776bdc6e6779',
        channelArn: 'arn:aws:ivs:us-east-1:610070956150:channel/92qzbV9SVGGh',
        rtmpUrl:
          'rtmps://0de9950180a8.global-contribute.live-video.net:443/app/sk_us-east-1_NG6PNjf1L8OZ_Xjnyq6vP67VL4r0yz480uONSmi79ak',
        playbackUrl:
          'https://0de9950180a8.us-east-1.playback.live-video.net/api/video/v1/us-east-1.610070956150.channel.92qzbV9SVGGh.m3u8',
      },
      // trade-room-3.banyanhill.com
      {
        InstanceId: 'i-0f59d37dd08147e0c',
        channelArn: 'arn:aws:ivs:us-east-1:610070956150:channel/VaWaaRgWvNVR',
        rtmpUrl:
          'rtmps://0de9950180a8.global-contribute.live-video.net:443/app/sk_us-east-1_rt1BWl8yeo5J_Dl4H3XXVG82qJnYvepCIlqAbl5tS7K',
        playbackUrl:
          'https://0de9950180a8.us-east-1.playback.live-video.net/api/video/v1/us-east-1.610070956150.channel.VaWaaRgWvNVR.m3u8',
      },
      // // trade-room-2 -> ivs-2 Instances
      // {
      //   InstanceId: 'i-0df5e5bc357f73a70',
      //   channelArn: 'arn:aws:ivs:us-east-1:174964136571:channel/nhTfCeCAKkIc',
      //   rtmpUrl:
      //     'rtmps://88f227d4d332.global-contribute.live-video.net:443/app/sk_us-east-1_h3JeMDnf8Sza_dm2OecGalS5232hthcG9AI7T0T8wwq',
      //   playbackUrl:
      //     'https://88f227d4d332.us-east-1.playback.live-video.net/api/video/v1/us-east-1.174964136571.channel.nhTfCeCAKkIc.m3u8',
      // },
      // // trade-room-4 -> ivs-3 Instances
      // {
      //   InstanceId: 'i-0a4f29b1c8c6f1a6b',
      //   channelArn: 'arn:aws:ivs:us-east-1:174964136571:channel/xDx4q1vgkAp2',
      //   rtmpUrl:
      //     'rtmps://88f227d4d332.global-contribute.live-video.net:443/app/sk_us-east-1_LjVqisuMazq7_m6j76LfqQMntCUfIHSHPw0L6c7ACAB',
      //   playbackUrl:
      //     'https://88f227d4d332.us-east-1.playback.live-video.net/api/video/v1/us-east-1.174964136571.channel.xDx4q1vgkAp2.m3u8',
      // },
      // // trade-room-5 -> ivs-1 Instances
      // {
      //   InstanceId: 'i-014179fd52c950d0d',
      //   channelArn: 'arn:aws:ivs:us-east-1:174964136571:channel/0Oa3CymRFHYV',
      //   rtmpUrl:
      //     'rtmps://88f227d4d332.global-contribute.live-video.net:443/app/sk_us-east-1_MyiOLZ1qt2Il_5sWkc3d0QdX9sHSwC1QP0ed0XO89z5',
      //   playbackUrl:
      //     'https://88f227d4d332.us-east-1.playback.live-video.net/api/video/v1/us-east-1.174964136571.channel.0Oa3CymRFHYV.m3u8',
      // },
    ],
    []
  );

  const startBroadcast = (instance: any) => {
    // RTMP_URL
    const userDataTemplate = `Content-Type: multipart/mixed; boundary="//"
      MIME-Version: 1.0

      --//
      Content-Type: text/cloud-config; charset="us-ascii"
      MIME-Version: 1.0
      Content-Transfer-Encoding: 7bit
      Content-Disposition: attachment; filename="cloud-config.txt"

      #cloud-config
      cloud_final_modules:
      - [scripts-user, always]

      --//
      Content-Type: text/x-shellscript; charset="us-ascii"
      MIME-Version: 1.0
      Content-Transfer-Encoding: 7bit
      Content-Disposition: attachment; filename="userdata.txt"

      #!/bin/bash
      cd /home/ubuntu/downloads/browser-to-rtmp-docker
      make run WEBSITE_URL='https://banyanhillweb.com/vdo/?scene=0&layout&meterstyle=2&sharperscreen&animated&fadein&room=${
        room?.name.split(' | ')[1]?.split(',')[0]
      }_tradeRoom&password=BanyanH!ll2@23' RTMP_URL=${instance.rtmpUrl} &
      --//--`;

    ec2
      // @ts-ignore
      .send(
        new ModifyInstanceAttributeCommand({
          InstanceId: instance.InstanceId,
          Attribute: 'userData',
          // match spacing to userDataTemplate const
          Value: btoa(userDataTemplate.replaceAll('\n      ', '\n')),
        })
      )
      .then((modifyRes: any) => {
        ec2
          // @ts-ignore
          .send(
            new StartInstancesCommand({
              InstanceIds: [instance.InstanceId],
            })
          )
          .then((startRes: any) => {
            setBroadcastState('init');
            updateGlobalAppState({
              channelArn: instance.channelArn,
              playbackUrl: instance.playbackUrl,
              id: instance.InstanceId,
              health: 'STARTING',
              state: 'Initializing ...',
              viewerCount: 0,
            });
          })
          .catch((startError: any) => {
            console.log('Error', startError);
            debugger;
          });
      })
      .catch((modifyError: any) => {
        console.log('Error', modifyError);
        debugger;
      });
  };

  const stopBroadcast = (instance: any) => {
    ec2
      // @ts-ignore
      .send(
        new StopInstancesCommand({
          InstanceIds: [instance.InstanceId],
        })
      )
      .then((stopRes: any) => {
        setBroadcastState('stop');
        updateGlobalAppState({
          channelArn: instance.channelArn,
          playbackUrl: instance.playbackUrl,
          id: instance.InstanceId,
          health: 'STOPPING',
          state: 'Disconnecting ...',
          viewerCount: 0,
        });
      })
      .catch((stopError: any) => {
        console.log('Error', stopError);
        debugger;
      });
  };

  useEffect(() => {
    if (globalAppState.instance?.id === undefined) return;

    const match = AWSInstances.find(item => item.InstanceId === globalAppState.instance?.id);

    if (!match) return;

    if (
      globalAppState.instance?.health === 'STARTING' ||
      globalAppState.instance?.health === 'HEALTHY' ||
      globalAppState.instance?.health === 'STARVING'
    ) {
      setBroadcastState('init');
      // return;
    }
    if (globalAppState.instance?.health === 'STOPPING') {
      setBroadcastState('stop');
      // return;
    }

    const intervalId = setInterval(() => {
      if (broadcastState === 'init') {
        ivs
          // @ts-ignore
          .send(new GetStreamCommand({ channelArn: match.channelArn }))
          .then((res: any) => {
            // actively monitoring do something with result
            console.log('stream running ...');

            updateGlobalAppState({
              channelArn: match.channelArn,
              playbackUrl: match.playbackUrl,
              id: match.InstanceId,
              health: res.stream?.health || '',
              state: res.stream?.state || null,
              viewerCount: res.stream?.viewerCount || 0,
            });
          })
          .catch((streamErr: any) => {
            console.log('stream initializing ...');

            updateGlobalAppState({
              channelArn: match.channelArn,
              playbackUrl: match.playbackUrl,
              id: match.InstanceId,
              health: 'STARTING',
              state: 'Initializing ...',
              viewerCount: 0,
            });
          });
      }

      if (broadcastState === 'stop') {
        ivs
          // @ts-ignore
          .send(new GetStreamCommand({ channelArn: match.channelArn }))
          .then((res: any) => {
            console.log('stream disconnecting ...');

            updateGlobalAppState({
              channelArn: match.channelArn,
              playbackUrl: match.playbackUrl,
              id: match.InstanceId,
              health: 'STOPPING',
              state: 'Disconnecting ...',
              viewerCount: res.stream?.viewerCount || 0,
            });
          })
          .catch((streamErr: any) => {
            console.log('stream stopped ...');

            clearInterval(intervalId);

            updateGlobalAppState({
              channelArn: undefined,
              playbackUrl: undefined,
              id: undefined,
              health: 'STOPPED',
              state: null,
              viewerCount: 0,
            });
          });
      }
    }, 3000);

    return () => {
      console.log('clearInterval: ', intervalId, broadcastState);
      clearInterval(intervalId);
    };
  }, [AWSInstances, broadcastState, globalAppState, ivs, updateGlobalAppState]);

  function toggleRecord() {
    ec2
      // @ts-ignore
      .send(new DescribeInstancesCommand({}))
      .then((describeRes: any) => {
        onClose();

        const tradeRooms = describeRes.Reservations.filter(
          (item: any) =>
            item.Instances[0].KeyName.includes('bh_trade-room') && item.Instances[0].State.Name === 'running'
        );

        if (tradeRooms.length > 0) {
          // create new instance if this instance should not end
          const match = tradeRooms.find((item: any) => item.Instances[0].InstanceId === globalAppState.instance?.id);

          if (match) {
            stopBroadcast(AWSInstances.find(item => item.InstanceId === globalAppState.instance.id));
          } else {
            startBroadcast(AWSInstances[tradeRooms.length]);
          }
        } else {
          startBroadcast(AWSInstances[0]);
        }
      })
      .catch((describeError: any) => {
        console.log('Error: ', describeError);
        debugger;
      });
  }

  return (
    <Dialog maxWidth="xs" open={open} {...other}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1" dangerouslySetInnerHTML={{ __html: props.content }}></Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleOk(props.action);
          }}
          className={clsx(classes.button)}
        >
          {props.confirmtext}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
