import React, { createContext, useCallback, useState } from 'react';
// import { Player as TwilioPlayer } from '@twilio/live-player-sdk';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

// TwilioPlayer.setLogLevel(TwilioPlayer.LogLevel.Error);

type PlayerContextType = {
  player: any | undefined;
  connect: (token: string) => Promise<void>;
  disconnect: () => void;
};

export const PlayerContext = createContext<PlayerContextType>(null!);

export const PlayerProvider: React.FC = ({ children }) => {
  const { onError } = useVideoContext();
  const [player, setPlayer] = useState();

  const { IVSPlayer } = window as any;

  // https://stackoverflow.com/a/38552302
  function parseJwt(token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }

  const connect = useCallback(
    (token: string) => {
      token = parseJwt(token);

      if (IVSPlayer.isPlayerSupported || IVSPlayer) {
        if (!IVSPlayer.core) {
          const newPlayer = IVSPlayer.create();

          if ((token as any).grants.player.playbackUrl.indexOf('undefined') === -1) {
            newPlayer.load((token as any).grants.player.playbackUrl);
          }
          setPlayer(newPlayer);
        } else {
          setPlayer(IVSPlayer);
        }

        return Promise.resolve();
      } else {
        onError(new Error('Player not supported in your browser.'));
        return Promise.reject();
      }
    },
    [onError, IVSPlayer]
  );

  const disconnect = () => {
    if (player) {
      setPlayer(undefined);
      if ((player as any)?.core?.state?.state !== 'Ended') {
        (player as any).pause();
      }
    }
  };

  return <PlayerContext.Provider value={{ connect, disconnect, player }}>{children}</PlayerContext.Provider>;
};
