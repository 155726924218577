import React, { useState, useRef } from 'react';
import { Button, Menu, MenuItem, MenuList, Divider } from '@material-ui/core';
import SpeakerMenuIcon from '../../../icons/SpeakerMenuIcon';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import useSyncContext from '../../../hooks/useSyncContext/useSyncContext';
import { removeSpeaker /*sendChatInvite*/ } from '../../../state/api/api';

export function SpeakerMenu({
  speaker,
  type,
  chatBans,
  eventBans,
}: {
  speaker: string;
  type: string;
  chatBans: any;
  eventBans: any;
}) {
  const [menuOpen, setMenuOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { room } = useVideoContext();
  const { chatBansMap, eventBansMap } = useSyncContext();

  async function switchToViewer() {
    setMenuOpen(false);
    await removeSpeaker(speaker, room!.name);
  }
  async function handleRemoveFromChat() {
    setMenuOpen(false);
    chatBansMap
      ?.get(speaker)
      .then(() => {
        chatBansMap?.remove(speaker);
      })
      .catch(error => {
        // author is not on map, add them
        chatBansMap?.set(speaker, {});
      });
  }
  async function handleRemoveFromEvent() {
    setMenuOpen(false);
    eventBansMap
      ?.get(speaker)
      .then(() => {
        eventBansMap?.remove(speaker);
      })
      .catch(error => {
        // author is not on map, add them
        eventBansMap?.set(speaker, {});
      });
  }
  // async function handlePrivateMessage() {
  //   sendChatInvite(`${room?.sid}-${speaker}`, room?.localParticipant.identity);
  // }

  const handleMuteSpeaker = () => {
    setMenuOpen(false);
    const [localDataTrackPublication] = [...room!.localParticipant.dataTracks.values()];
    const messageString = JSON.stringify({ message_type: 'mute', to_participant_identity: speaker });
    // Here we convert the message from stringified JSON to an ArrayBuffer. Sending/receiving ArrayBuffers
    // in the DataTracks helps with interoperability with the iOS Twilio Live App.
    const messageBuffer = new TextEncoder().encode(messageString).buffer;
    localDataTrackPublication.track.send(messageBuffer);
  };

  return (
    <>
      <Button onClick={() => setMenuOpen(isOpen => !isOpen)} ref={anchorRef}>
        <SpeakerMenuIcon />
      </Button>
      <Menu
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuList dense>
          {type === 'speaker' && (
            <>
              <MenuItem onClick={handleMuteSpeaker}>Mute Speaker</MenuItem>
              <MenuItem onClick={switchToViewer} style={{ color: 'red' }}>
                Move To Viewers
              </MenuItem>
              <Divider />
            </>
          )}
          {/* <MenuItem onClick={handlePrivateMessage}>Send Private Message</MenuItem> */}
          <MenuItem onClick={handleRemoveFromChat} style={{ color: 'red' }}>
            {chatBans.includes(speaker) ? `Unmute in Chat` : `Mute in Chat`}
          </MenuItem>
          <MenuItem onClick={handleRemoveFromEvent} style={{ color: 'red' }}>
            {eventBans.includes(speaker) ? `Unban` : `Ban`}
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
}
