import { createTheme } from '@material-ui/core';
import { MAM_DOMAINS } from './constants';

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    sidebarWidth: number;
    sidebarMobileHeight: number;
    brand: string;
    brandBackgroundColor: string;
    footerHeight: number;
    mobileTopBarHeight: number;
    mobileFooterHeightHost: number;
    mobileFooterHeightViewer: number;
    sidebarMobilePadding: number;
    participantBorderWidth: number;
    rightDrawerWidth: number;
    headerHeight: number;
    tabHeaderHeight: number;
    mobileHeaderHeight: number;
    headerLogoHeight: number;
    mobileHeaderLogoHeight: number;
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    sidebarWidth?: number;
    sidebarMobileHeight?: number;
    brand: string;
    brandBackgroundColor: string;
    footerHeight: number;
    mobileTopBarHeight: number;
    mobileFooterHeightHost: number;
    mobileFooterHeightViewer: number;
    sidebarMobilePadding: number;
    participantBorderWidth: number;
    rightDrawerWidth?: number;
    headerHeight: number;
    tabHeaderHeight: number;
    mobileHeaderHeight: number;
    headerLogoHeight: number;
    mobileHeaderLogoHeight: number;
  }
}

const domainOrigin = MAM_DOMAINS.includes(window.location.origin) ? 'mam' : 'banyan';

const defaultTheme = createTheme();

const defaultValues = {
  brand: domainOrigin === 'mam' ? '#ff0000' : '#E22525', // brand needs to be based off pubcode
  brandBackgroundColor: domainOrigin === 'mam' ? '#205d34' : '#113752',
  footerHeight: 72,
  mobileFooterHeightHost: 74,
  mobileFooterHeightViewer: 56,
  sidebarWidth: 300,
  sidebarMobileHeight: 90,
  sidebarMobilePadding: 8,
  participantBorderWidth: 2,
  mobileTopBarHeight: 52,
  rightDrawerWidth: 320,
  headerHeight: 96,
  tabHeaderHeight: 48,
  mobileHeaderHeight: 50, // unknown
  headerLogoHeight: 40,
  mobileHeaderLogoHeight: 40,
};

export default createTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        'html, body, #root': {
          height: '100%',
        },
        '#root > div:last-child': {
          bottom: `${defaultValues.footerHeight}px`,
          [defaultTheme.breakpoints.down('sm')]: {
            width: '100%',
          },
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: '4px',
        textTransform: 'none',
        color: 'rgb(40, 42, 43)',
        fontSize: '0.9rem',
        transition: defaultTheme.transitions.create(['background-color', 'box-shadow', 'border', 'color'], {
          duration: defaultTheme.transitions.duration.short,
        }),
      },
      text: {
        padding: '6px 14px',
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      outlinedPrimary: {
        border: '2px solid #027AC5',
        '&:hover': {
          border: '2px solid rgb(1, 85, 137)',
        },
      },
      startIcon: {
        marginRight: '0',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '0.9rem',
      },
    },
    MuiTypography: {
      body1: {
        color: 'rgb(40, 42, 43)',
        fontSize: '0.9rem',
      },
      h4: {
        fontSize: '1.8rem',
      },
      caption: {
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: '0.6rem',
        },
      },
      body2: {
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: '0.75rem',
        },
      },
    },
    MuiInputBase: {
      root: {
        fontSize: '0.9rem',
      },
    },
    MuiSelect: {
      root: {
        padding: '0.85em',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '16px',
      },
    },
    MuiTextField: {
      root: {
        color: 'rgb(40, 42, 43)',
      },
    },
    MuiInputLabel: {
      root: {
        color: 'rgb(40, 42, 43)',
        fontSize: '1.1rem',
        marginBottom: '0.2em',
        fontWeight: 500,
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: 'rgb(136, 140, 142)',
      },
    },
    MuiTab: {
      root: {
        [defaultTheme.breakpoints.down('sm')]: {
          minWidth: '72px',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
  },
  palette: {
    primary: {
      main: '#027AC5',
    },
    secondary: {
      main: defaultValues.brand,
    },
  },
  brand: defaultValues.brand,
  brandBackgroundColor: defaultValues.brandBackgroundColor,
  footerHeight: defaultValues.footerHeight,
  mobileFooterHeightHost: defaultValues.mobileFooterHeightHost,
  mobileFooterHeightViewer: defaultValues.mobileFooterHeightViewer,
  sidebarWidth: defaultValues.sidebarWidth,
  sidebarMobileHeight: defaultValues.sidebarMobileHeight,
  sidebarMobilePadding: defaultValues.sidebarMobilePadding,
  participantBorderWidth: defaultValues.participantBorderWidth,
  mobileTopBarHeight: defaultValues.mobileTopBarHeight,
  rightDrawerWidth: defaultValues.rightDrawerWidth,
  headerHeight: defaultValues.headerHeight,
  tabHeaderHeight: defaultValues.tabHeaderHeight,
  mobileHeaderHeight: defaultValues.mobileHeaderHeight,
  headerLogoHeight: defaultValues.headerLogoHeight,
  mobileHeaderLogoHeight: defaultValues.mobileHeaderLogoHeight,
});
