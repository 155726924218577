import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useAppState } from '../../state';
import { useModeratorsMap } from '../../hooks/useModeratorsMap/useModeratorsMap';
import { SpeakerMenu } from '../SpeakersList/SpeakerMenu/SpeakerMenu';

interface ModeratorsListProps {
  chatBans: any;
  eventBans: any;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontWeight: 'bold',
      padding: '0.8em 0',
      color: 'blue',
    },
    moderatorsContainer: {
      padding: '0.4em 1em',
      '& p': {
        padding: '0.75em 0',
      },
      overflow: 'auto',
      flexShrink: 0,
      maxHeight: 'calc(25% - 28px)', //participantWindowHeader is 56px
      minHeight: '55px',
      borderBottom: '0.1em solid #CACDD8',
    },
    moderatorContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
      position: 'relative',
      '& button': {
        display: 'none',
        position: 'absolute',
        top: '0',
        right: '0',
      },
      '&:hover button': {
        display: 'inline-flex',
      },
    },
  })
);

export default function ModeratorsList({ chatBans, eventBans }: ModeratorsListProps) {
  const { appState } = useAppState();
  const moderators = useModeratorsMap();
  const moderatorCount = moderators.length;

  const classes = useStyles();

  return (
    <div className={classes.moderatorsContainer}>
      <div className={classes.header}>{`Moderators (${moderatorCount})`}</div>
      {moderators.map(moderator => (
        <div key={moderator} className={classes.moderatorContainer}>
          <Typography variant="body1">
            {appState.participantName === moderator ? `${moderator} (You)` : moderator}{' '}
          </Typography>
          {appState.participantName !== moderator && (
            <SpeakerMenu chatBans={chatBans} eventBans={eventBans} speaker={moderator} type="moderator" />
          )}
        </div>
      ))}
    </div>
  );
}
