import React, { useCallback, useRef } from 'react';

import { Button, Tooltip } from '@material-ui/core';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import VideocamOffOutlinedIcon from '@material-ui/icons/VideocamOffOutlined';

import useDevices from '../../../hooks/useDevices/useDevices';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';
import { useEnqueueSnackbar } from '../../../hooks/useSnackbar/useSnackbar';

export default function ToggleVideoButton(props: {
  disabled?: boolean;
  className?: string;
  isSharingScreen?: boolean;
}) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { hasVideoInputDevices } = useDevices();
  const enqueueSnackbar = useEnqueueSnackbar();

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();

      if (props.isSharingScreen && !isVideoEnabled) {
        // toggleVideoEnabled(props.isSharingScreen);
        enqueueSnackbar({
          headline: '',
          message: 'Cannot start video while Screen Share is enabled. Stop sharing, start video then share screen.',
          variant: 'info',
        });
      } else {
        toggleVideoEnabled();
      }
    }
  }, [toggleVideoEnabled, props.isSharingScreen, enqueueSnackbar, isVideoEnabled]);

  return (
    <>
      <Tooltip
        title={isVideoEnabled ? 'Stop Video' : 'Start Video'}
        placement="top"
        PopperProps={{ disablePortal: true }}
      >
        <span>
          <Button className={props.className} onClick={toggleVideo} disabled={!hasVideoInputDevices || props.disabled}>
            {isVideoEnabled ? <VideocamOutlinedIcon fontSize="large" /> : <VideocamOffOutlinedIcon fontSize="large" />}
            {!hasVideoInputDevices ? 'No Video' : ''}
          </Button>
        </span>
      </Tooltip>
    </>
  );
}
