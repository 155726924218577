import React, { useState, ChangeEvent, FormEvent } from 'react';
import { appActionTypes, ActiveScreen, appStateTypes } from '../../../state/appState/appReducer';
import {
  Typography,
  makeStyles,
  TextField,
  Grid,
  Button,
  InputLabel,
  Theme,
  Select,
  MenuItem,
  Link,
  CircularProgress,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1.5em',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '0.5em',
    },
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 3.5em 0',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  togglePassLink: {
    color: '#ff6600',
    cursor: 'pointer',
  },
}));

interface JoinEventScreenProps {
  state: appStateTypes;
  dispatch: React.Dispatch<appActionTypes>;
  connect: () => void;
  getRooms: any;
  user: any;
}

export default function JoinEventScreen({ state, dispatch, connect, getRooms, user }: JoinEventScreenProps) {
  const classes = useStyles();
  const [roomsList, setRoomsList] = useState<string[] | undefined>();
  const [isGettingRooms, setIsGettingRooms] = useState(false);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'set-event-name', eventName: event.target.value });
  };

  const handleSelectChange = (event: ChangeEvent<{}>) => {
    const target = event.target as HTMLInputElement;
    dispatch({ type: 'set-event-name', eventName: target.value });
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (state.participantType === 'speaker') {
      dispatch({ type: 'set-active-screen', activeScreen: ActiveScreen.DeviceSelectionScreen });
    } else {
      connect();
    }
  };

  async function getRoomsList() {
    return await getRooms(user.token ? user.token : undefined);
  }

  if (typeof roomsList === 'undefined' && !isGettingRooms) {
    setIsGettingRooms(true);
    getRoomsList().then(list => {
      setRoomsList((list as any)?.data?.roomList);
      setIsGettingRooms(false);

      if ((list as any)?.data?.roomList.length === 1) {
        dispatch({ type: 'set-event-name', eventName: (list as any)?.data?.roomList[0].uniqueName });
      }
    });
  }

  return (
    <div style={{ display: 'flex', flexFlow: 'column', flexGrow: 1, justifyContent: 'space-between' }}>
      {isGettingRooms && (
        <Grid container justifyContent="center" alignItems="center" direction="column" style={{ height: '100%' }}>
          <div>
            <CircularProgress variant="indeterminate" />
          </div>
          <div>
            <Typography style={{ fontWeight: 'bold', fontSize: '16px' }}>Getting Active Events ...</Typography>
          </div>
        </Grid>
      )}
      {!isGettingRooms && (!roomsList || (roomsList && roomsList.length !== 0)) && (
        <>
          <Typography variant="h5" className={classes.gutterBottom}>
            Join Event
          </Typography>
          <div style={{ minHeight: '140px' }}>
            <div className={classes.textFieldContainer} style={{ marginBottom: '3.5em' }}>
              <InputLabel shrink htmlFor="select-room" style={{ fontWeight: 'bold' }}>
                Event name
              </InputLabel>
              <Select
                onChange={handleSelectChange}
                value={state.eventName || '0'}
                variant="outlined"
                id="select-room"
                fullWidth
              >
                <MenuItem value="0" selected disabled>
                  {`(${roomsList?.length}) Events Available`}
                </MenuItem>
                {roomsList &&
                  (roomsList as any).map((room: any) => {
                    return (
                      <MenuItem value={room.uniqueName} key={room.sid}>
                        {room.uniqueName.split(' | ')[0]}
                      </MenuItem>
                    );
                  })}
              </Select>
            </div>
            <Grid container justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                disabled={!state.eventName}
                className={classes.continueButton}
                onClick={connect}
              >
                {state.participantType === 'speaker' ? 'Continue' : 'Join'}
              </Button>
            </Grid>
          </div>
          <Typography
            className={classes.gutterBottom}
            style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}
          >
            Problems? Try&nbsp;
            <Link
              onClick={() => {
                setRoomsList(undefined);
              }}
              className={classes.togglePassLink}
            >
              refreshing the event list
            </Link>
            &nbsp;or&nbsp;
            <Link
              onClick={() => {
                setRoomsList([]);
                dispatch({ type: 'set-event-name', eventName: '' });
              }}
              className={classes.togglePassLink}
            >
              joining by event name
            </Link>
            .
          </Typography>
        </>
      )}
      {roomsList && roomsList.length === 0 && (
        <>
          <Typography variant="h5" className={classes.gutterBottom}>
            Join Event
          </Typography>
          <Typography variant="h6" className={classes.gutterBottom}>
            No active events found!
          </Typography>
          <Typography>
            If this is an error, please{' '}
            <Link
              onClick={() => {
                setRoomsList(undefined);
              }}
              className={classes.togglePassLink}
            >
              refresh the event list
            </Link>{' '}
            or try again later. If you know the name of an active event, enter it below.
          </Typography>
          <form onSubmit={handleSubmit}>
            <div className={classes.inputContainer}>
              <div className={classes.textFieldContainer}>
                <InputLabel shrink htmlFor="input-user-name" style={{ fontWeight: 'bold' }}>
                  Event name
                </InputLabel>
                <TextField
                  id="input-user-name"
                  autoFocus
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={state.eventName}
                  onChange={handleNameChange}
                />
              </div>
            </div>
            <Grid container justifyContent="flex-end">
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={!state.eventName}
                className={classes.continueButton}
              >
                {state.participantType === 'speaker' ? 'Continue' : 'Join'}
              </Button>
            </Grid>
          </form>
        </>
      )}
    </div>
  );
}
