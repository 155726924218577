import React, { useEffect, useRef, useState } from 'react';
import { Button, Tooltip, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import linkify from 'linkify-it';
import { Conversation } from '@twilio/conversations';
import FileAttachmentIcon from '../../../icons/FileAttachmentIcon';
import CrownIcon from '../../../icons/CrownIcon';
import AlertIconTriangle from '../../../icons/AlertIconTriangle';
import { isMobile } from '../../../utils';
import SendMessageIcon from '../../../icons/SendMessageIcon';
import Snackbar from '../../Snackbar/Snackbar';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const useStyles = makeStyles(theme => ({
  chatInputContainer: {
    borderTop: '1px solid #E4E7E9',
    padding: '1em 1.2em 1em',
    background: 'rgba(0,0,0,0.04)',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  textArea: {
    width: '100%',
    border: '0',
    resize: 'none',
    fontSize: '14px',
    fontFamily: 'Inter',
    outline: 'none',
  },
  button: {
    padding: '0.56em',
    minWidth: 'auto',
    '& path': {
      fill: '#707578',
    },
    '&.MuiButton-containedPrimary path': {
      fill: 'white',
    },
    '&:disabled': {
      background: 'none',
      '& path': {
        fill: '#d8d8d8',
      },
    },
    '&.active': {
      background: '#027AC5',
      '& path': {
        fill: 'white',
      },
    },
  },
  buttonContainer: {
    margin: '1em 0 0 1em',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      margin: '0',
    },
  },
  gridContainer: {
    [theme.breakpoints.down('sm')]: {
      flex: 1,
    },
  },
  fileButtonContainer: {
    position: 'relative',
    marginRight: '1em',
  },
  fileButtonLoadingSpinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  textAreaContainer: {
    display: 'flex',
    marginTop: '0.4em',
    padding: '0.48em 0.7em',
    border: '2px solid transparent',
    background: 'white',
    [theme.breakpoints.down('sm')]: {
      flex: '1 1 100%',
    },
  },
  isTextareaFocused: {
    borderColor: theme.palette.primary.main,
    borderRadius: '4px',
  },
  isTextareaAnnouncement: {
    borderColor: '#FFCC00',
    borderRadius: '4px',
  },
}));

interface ChatInputProps {
  conversation: Conversation;
  isChatWindowOpen: boolean;
  isAttachmentEnabled?: boolean;
  appState: any;
  globalAppStateMap: any;
  pubcode: string;
}

const ALLOWED_FILE_TYPES =
  'audio/*, image/*, text/*, video/*, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document .xslx, .ppt, .pdf, .key, .svg, .csv';

export default function ChatInput({
  conversation,
  isChatWindowOpen,
  isAttachmentEnabled,
  appState,
  globalAppStateMap,
  pubcode,
}: ChatInputProps) {
  const classes = useStyles();
  const [messageBody, setMessageBody] = useState('');
  const [isAnnouncement, setIsAnnouncement] = useState(false);
  const [isSendingFile, setIsSendingFile] = useState(false);
  const [fileSendError, setFileSendError] = useState<string | null>(null);
  const isValidMessage = /\S/.test(messageBody);
  const textInputRef = useRef<HTMLTextAreaElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isTextareaFocused, setIsTextareaFocused] = useState(false);

  function hasLink(text: string) {
    const matches = linkify().match(text);
    if (!matches) return false;

    return true;
  }

  function addLinks(text: string) {
    const matches = linkify().match(text);
    if (!matches) return text;

    const results = [];
    let lastIndex = 0;

    matches.forEach((match, i) => {
      results.push(text.slice(lastIndex, match.index));
      results.push(`<a target="_blank" rel="noreferrer" href=${match.url} key=${i}>${match.text}</a>`);
      lastIndex = match.lastIndex;
    });

    results.push(text.slice(lastIndex, text.length));

    return results.join('');
  }

  useEffect(() => {
    if (isChatWindowOpen) {
      // When the chat window is opened, we will focus on the text input.
      // This is so the user doesn't have to click on it to begin typing a message.
      textInputRef.current?.focus();
    }
  }, [isChatWindowOpen]);

  let Filter = require('bad-words');

  // Fix for outdated bad words filter
  // https://github.com/web-mech/badwords/issues/93#issuecomment-821727619
  const cleanEmojis = (string: string) => {
    try {
      return filter.clean(string);
    } catch {
      const joinMatch = filter.splitRegex.exec(string);
      const joinString = (joinMatch && joinMatch[0]) || '';

      return string
        .split(filter.splitRegex)
        .map(word => {
          return filter.isProfane(word) ? filter.replaceWord(word) : word;
        })
        .join(joinString);
    }
  };

  let filter = new Filter();

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessageBody(event.target.value);
  };

  // ensures pressing enter + shift creates a new line, so that enter on its own only sends the message:
  const handleReturnKeyPress = (event: React.KeyboardEvent) => {
    if (!isMobile && event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage(messageBody);
    }
  };

  const handleSendMessage = (message: string) => {
    if (isValidMessage) {
      if (appState.participantType === 'host' || appState.participantType === 'moderator') {
        if (isAnnouncement === true) {
          // @ts-ignore
          gtag('event', 'message_send', {
            eventCategory: 'Video',
            eventAction: 'Host Announcement Send',
            eventLabel: appState.eventName,
          });

          conversation
            .sendMessage(addLinks(cleanEmojis(message.trim())) as string, {
              host_announcement: true,
              host_chat: true,
            })
            .then(index => {
              //store index
              globalAppStateMap?.getItems({ pageSize: 100 }).then((paginator: any) => {
                if (paginator.items[0].data.hasOwnProperty('announcements')) {
                  globalAppStateMap.update('globalAppState', {
                    ...paginator.items[0].data,
                    announcements: [...paginator.items[0].data.announcements, index],
                  });
                } else {
                  globalAppStateMap.update('globalAppState', {
                    ...paginator.items[0].data,
                    announcements: [index],
                  });
                }
              });
            });
        } else {
          let msg_arguments =
            appState.participantType === 'host'
              ? {
                  host_chat: true,
                }
              : {
                  moderator_chat: true,
                };
          // @ts-ignore
          gtag('event', 'message_send', {
            eventCategory: 'Video',
            eventAction: 'Host Message Send',
            eventLabel: appState.eventName,
          });
          conversation.sendMessage(addLinks(cleanEmojis(message.trim())) as string, msg_arguments as any);
        }
      } else {
        // @ts-ignore
        gtag('event', 'message_send', {
          eventCategory: 'Video',
          eventAction: 'Viewer Message Send',
          eventLabel: appState.eventName,
        });
        hasLink(message)
          ? conversation.sendMessage(cleanEmojis(message.trim()), {
              has_link: true,
            })
          : conversation.sendMessage(cleanEmojis(message.trim()));
      }
      setMessageBody('');
      setIsAnnouncement(false);
    }
  };

  const handleSendFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      var formData = new FormData();
      formData.append('userfile', file);
      setIsSendingFile(true);
      setFileSendError(null);

      // @ts-ignore
      gtag('event', 'message_send', {
        eventCategory: 'Video',
        eventAction: 'File Send',
        eventLabel: appState.eventName,
      });

      conversation
        .sendMessage(
          formData,
          appState.participantType === 'host'
            ? {
                host_chat: true,
              }
            : {
                moderator_chat: true,
              }
        )
        .catch(e => {
          if (e.code === 413) {
            setFileSendError('File size is too large. Maximum file size is 150MB.');
          } else {
            setFileSendError('There was a problem uploading the file. Please try again.');
          }
          console.log('Problem sending file: ', e);
        })
        .finally(() => {
          setIsSendingFile(false);
        });
    }
  };

  const getFormattedAuthor = (author: string) => {
    let authorArray = author?.split(' ');

    if (appState.participantType === 'host' || appState.participantType === 'moderator') return author;

    if (authorArray.length > 1) {
      return `${authorArray[0]} ${authorArray[authorArray.length - 1].charAt(0)}.`;
    } else {
      return author;
    }
  };

  return (
    <div className={classes.chatInputContainer}>
      <Snackbar
        open={Boolean(fileSendError)}
        headline="Error"
        message={fileSendError || ''}
        variant="error"
        handleClose={() => setFileSendError(null)}
      />
      <div
        className={clsx(classes.textAreaContainer, {
          [classes.isTextareaFocused]: isTextareaFocused,
          [classes.isTextareaAnnouncement]: isAnnouncement && isTextareaFocused,
        })}
      >
        {/* 
        Here we add the "isTextareaFocused" class when the user is focused on the TextareaAutosize component.
        This helps to ensure a consistent appearance across all browsers. Adding padding to the TextareaAutosize
        component does not work well in Firefox. See: https://github.com/twilio/twilio-video-app-react/issues/498
        */}
        <TextareaAutosize
          minRows={1}
          maxRows={3}
          className={classes.textArea}
          aria-label="chat input"
          placeholder={
            isAnnouncement
              ? `Send announcement to all viewers`
              : `Chat publicly as ${getFormattedAuthor(appState.participantName)}`
          }
          onKeyPress={handleReturnKeyPress}
          onChange={handleChange}
          value={messageBody}
          data-cy-chat-input
          ref={textInputRef}
          onFocus={() => setIsTextareaFocused(true)}
          onBlur={() => setIsTextareaFocused(false)}
        />
      </div>

      <Grid
        container
        classes={{ container: classes.gridContainer }}
        alignItems="flex-end"
        justifyContent="flex-end"
        wrap="nowrap"
      >
        {/* Since the file input element is invisible, we can hardcode an empty string as its value.
        This allows users to upload the same file multiple times. */}
        <input
          ref={fileInputRef}
          type="file"
          style={{ display: 'none' }}
          onChange={handleSendFile}
          value={''}
          accept={ALLOWED_FILE_TYPES}
        />
        <div className={classes.buttonContainer}>
          {(appState.participantType === 'host' || appState.participantType === 'moderator' || isAttachmentEnabled) && (
            <>
              <div className={classes.fileButtonContainer}>
                <Tooltip title="Send Attachment" placement="top">
                  <span>
                    <Button
                      className={classes.button}
                      onClick={() => fileInputRef.current?.click()}
                      disabled={isSendingFile}
                    >
                      <FileAttachmentIcon />
                    </Button>
                  </span>
                </Tooltip>
                {isSendingFile && <CircularProgress size={24} className={classes.fileButtonLoadingSpinner} />}
              </div>
            </>
          )}
          {(appState.participantType === 'host' || appState.participantType === 'moderator') && (
            <Tooltip title={isAnnouncement ? `Disable Announcement` : `Enable Announcement`} placement="top">
              <span>
                <Button
                  className={clsx(classes.button, isAnnouncement ? 'active' : '')}
                  onClick={() => setIsAnnouncement(!isAnnouncement)}
                >
                  {pubcode === 'TKO' ? <CrownIcon /> : <AlertIconTriangle />}
                </Button>
              </span>
            </Tooltip>
          )}
          <Tooltip title={isAnnouncement ? `Send Announcement` : `Send Message`} placement="top">
            <span>
              <Button
                className={classes.button}
                onClick={() => handleSendMessage(messageBody)}
                color="primary"
                variant="contained"
                disabled={!isValidMessage}
                data-cy-send-message-button
                style={{ marginLeft: '1em' }}
              >
                <SendMessageIcon />
              </Button>
            </span>
          </Tooltip>
        </div>
      </Grid>
    </div>
  );
}
