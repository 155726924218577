import React, { useState, useRef } from 'react';

import { makeStyles, Button, Tooltip } from '@material-ui/core';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOff from '@material-ui/icons/VolumeOff';
import usePlayerContext from '../../../hooks/usePlayerContext/usePlayerContext';

export const ANIMATION_DURATION = 700;

const useStyles = makeStyles({
  active: {
    textDecoration: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    borderRadius: '4px 0 0 4px',
  },
  volumeControl: {
    display: 'flex',
    justifyContent: 'center',
  },
  volumeContainer: {
    position: 'absolute',
    left: '0',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    padding: '0.8em',
    margin: '0 0 0 64px',
  },
  currentVolume: {
    cursor: 'pointer',
  },
});

export default function VolumeControlButton() {
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);
  const [playerMuted, setPlayerMuted] = useState(false);
  const [playerVolume, setPlayerVolume] = useState(50);
  const { player } = usePlayerContext();

  const anchorRef = useRef<HTMLButtonElement>(null);

  const changeVolume = (event: any) => {
    const newVolume = event.target.value;

    if (player !== null) {
      player?.setVolume(newVolume / 100);
      setPlayerVolume(newVolume);
      setPlayerMuted(false);

      if (newVolume <= 1) {
        setPlayerMuted(true);
      }
    }
  };

  const muteVolumeToggle = (event: any) => {
    if (event.target.tagName === 'path' || event.target.tagName === 'BUTTON' || event.target.tagName === 'svg') {
      if (player !== undefined && player.core.state.volume <= 0.01) {
        player?.setVolume(0.5);
        setPlayerVolume(50);

        setPlayerMuted(false);
      } else {
        player?.setVolume(0);
        setPlayerVolume(0);

        setPlayerMuted(true);
      }
    } else {
      changeVolume(event);
    }
  };

  return (
    <>
      <Tooltip title="Volume Control" placement="top">
        <Button
          className={menuOpen ? classes.active : ''}
          ref={anchorRef}
          onMouseOver={() => setMenuOpen(true)}
          onMouseLeave={() => setMenuOpen(false)}
          onClick={muteVolumeToggle}
        >
          {playerMuted ? <VolumeOff fontSize="large" /> : <VolumeUpIcon fontSize="large" />}
          {menuOpen && (
            <div className={classes.volumeContainer}>
              <div id="volumeControl" className={classes.volumeControl}>
                <div className={classes.volumeControl}>
                  <input
                    id="currentVolume"
                    value={playerVolume}
                    className={classes.currentVolume}
                    type="range"
                    min="1"
                    max="100"
                    onChange={changeVolume}
                  />
                </div>
              </div>
            </div>
          )}
        </Button>
      </Tooltip>
    </>
  );
}
