import React, { useState, useRef } from 'react';
import SettingsApplicationsOutlinedIcon from '@material-ui/icons/SettingsApplicationsOutlined';
import SettingsIcon from '../../../icons/SettingsIcon';
import LowerHandIcon from '../../../icons/LowerHandIcon';
import RaiseHandIcon from '../../../icons/RaiseHandIcon';
import clsx from 'clsx';
import {
  makeStyles,
  Button,
  Tooltip,
  styled,
  Theme,
  useMediaQuery,
  Menu as MenuContainer,
  MenuItem,
  Typography,
} from '@material-ui/core';
import useSyncContext from '../../../hooks/useSyncContext/useSyncContext';

const useStyles = makeStyles({
  active: {
    textDecoration: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
});

export const IconContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '1.5em',
  marginRight: '0.3em',
});

export default function Menu({ buttonClassName, globalAppState }: { buttonClassName?: string; globalAppState: any }) {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [menuOpen, setMenuOpen] = useState(false);

  const anchorRef = useRef<HTMLButtonElement>(null);
  const { globalAppStateMap } = useSyncContext();

  const toggleChatEnabled = () => {
    globalAppStateMap?.getItems({ pageSize: 100 }).then(items => {
      globalAppStateMap.update('globalAppState', {
        ...items.items[0].data,
        isChatEnabled: !globalAppState.isChatEnabled,
      });
    });
  };

  const toggleChatLinkEnabled = () => {
    globalAppStateMap?.getItems({ pageSize: 100 }).then(items => {
      globalAppStateMap.update('globalAppState', {
        ...items.items[0].data,
        isChatLinkEnabled: !globalAppState.isChatLinkEnabled,
      });
    });
  };

  const toggleAttachmentEnabled = () => {
    globalAppStateMap?.getItems({ pageSize: 100 }).then(items => {
      globalAppStateMap.update('globalAppState', {
        ...items.items[0].data,
        isAttachmentEnabled: !globalAppState.isAttachmentEnabled,
      });
    });
  };

  const toggleHandleRaiseHand = () => {
    globalAppStateMap?.getItems({ pageSize: 100 }).then(items => {
      globalAppStateMap.update('globalAppState', {
        ...items.items[0].data,
        isRaiseHandEnabled: !globalAppState.isRaiseHandEnabled,
      });
    });
  };

  return (
    <>
      <Tooltip title="Configure Room" placement="top">
        <Button
          onClick={() => setMenuOpen(isOpen => !isOpen)}
          ref={anchorRef}
          className={clsx(buttonClassName, { [classes.active]: menuOpen })}
          data-cy-more-button
        >
          <SettingsApplicationsOutlinedIcon fontSize="large" />
        </Button>
      </Tooltip>
      <MenuContainer
        open={menuOpen}
        onClose={() => setMenuOpen(isOpen => !isOpen)}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: isMobile ? -55 : 'bottom',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={toggleChatEnabled}>
          <IconContainer>
            <SettingsIcon />
          </IconContainer>
          <Typography variant="body1">{globalAppState.isChatEnabled ? `Disable` : `Enable`} Chat</Typography>
        </MenuItem>

        <MenuItem onClick={toggleAttachmentEnabled}>
          <IconContainer>
            <SettingsIcon />
          </IconContainer>
          <Typography variant="body1">
            {globalAppState.isAttachmentEnabled ? `Disable` : `Enable`} Attachments
          </Typography>
        </MenuItem>

        <MenuItem onClick={toggleChatLinkEnabled}>
          <IconContainer>
            <SettingsIcon />
          </IconContainer>
          <Typography variant="body1">
            {globalAppState.isChatLinkEnabled ? `Disable` : `Enable`} Links in Chat
          </Typography>
        </MenuItem>

        <MenuItem onClick={toggleHandleRaiseHand}>
          <IconContainer>{globalAppState.isRaiseHandEnabled ? <LowerHandIcon /> : <RaiseHandIcon />}</IconContainer>
          <Typography variant="body1">{globalAppState.isRaiseHandEnabled ? `Disable` : `Enable`} Raise Hand</Typography>
        </MenuItem>
      </MenuContainer>
    </>
  );
}
