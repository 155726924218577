import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  infoContainer: {
    position: 'absolute',
    zIndex: 2,
    height: '100%',
    width: '100%',
  },
  fullWidth: {
    gridArea: '1 / 1 / 2 / 3',
    [theme.breakpoints.down('sm')]: {
      gridArea: '1 / 1 / 3 / 3',
    },
  },
}));

export default function MainParticipant({
  host,
  director,
  participantName,
}: {
  host?: string;
  director?: boolean;
  participantName: string;
}) {
  const { room } = useVideoContext();
  const classes = useStyles();

  return (
    <>
      <div
        data-cy-main-participant
        data-cy-participant={participantName}
        className={clsx(classes.container, classes.fullWidth)}
      >
        {director ? (
          <iframe
            title="vdoNinja"
            src={`https://banyanhillweb.com/vdo/mixer.html?director=${
              room?.name.split(' | ')[1]?.split(',')[0]
            }_tradeRoom&q&push=${participantName?.replace(/[\W_]+/g, '') +
              '_' +
              room?.name.split(' | ')[1]?.split(',')[0]}&codirector=${encodeURIComponent(
              'BanyanH!ll2@23'
            )}&password=${encodeURIComponent(
              'BanyanH!ll2@23'
            )}&api=FnHKZbesUs&hidesolo&novice&locked&hidehome&meterstyle&orderby&screensharelabel=${encodeURIComponent(
              participantName?.replace(/[\W_]+/g, '_') + '_Screen'
            )}&screenshareid=${participantName?.replace(/[\W_]+/g, '') +
              '_' +
              room?.name.split(' | ')[1]?.split(',')[0] +
              '_Screen'}&slotmode&quality&label=${encodeURIComponent(participantName?.replace(/[\W_]+/g, '_'))}`}
            width={'100%'}
            height={'100%'}
            allow="autoplay;camera;microphone;fullscreen;picture-in-picture;display-capture;midi;geolocation;"
          />
        ) : (
          <iframe
            title="vdoNinja"
            src={`https://banyanhillweb.com/vdo/?room=${
              room?.name.split(' | ')[1]?.split(',')[0]
            }_tradeRoom&q&layout&locked&mini&webcam&hidetranslate&hideheader&screensharebutton&meterstyle=2&screensharelabel=${encodeURIComponent(
              participantName?.replace(/[\W_]+/g, '_') + '_Screen'
            )}&avatar=${encodeURIComponent(
              'https://banyanhillweb.com/vdo/media/gurus/600x600_MeetOurExperts_' +
                participantName?.replace(/[\W_]+/g, '') +
                '.png'
            )}&hidehome&push=${participantName?.replace(/[\W_]+/g, '') +
              '_' +
              room?.name.split(' | ')[1]?.split(',')[0]}&screenshareid=${participantName?.replace(/[\W_]+/g, '') +
              '_' +
              room?.name.split(' | ')[1]?.split(',')[0] +
              '_Screen'}&password=${encodeURIComponent(
              'BanyanH!ll2@23'
            )}&api=FnHKZbesUs&quality&label=${encodeURIComponent(participantName?.replace(/[\W_]+/g, '_'))}`}
            className={clsx(classes.infoContainer)}
            allow="autoplay;camera;microphone;fullscreen;picture-in-picture;display-capture;midi;geolocation;"
          />
        )}
      </div>
    </>
  );
}
