import React, { useState } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import { Button } from '@material-ui/core';
import ConfirmBroadcastDialog from '../../ConfirmBroadcastDialog/ConfirmBroadcastDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: 'white',
      marginLeft: '1em',
      '&:hover': {
        background: '#600101',
      },
      '&:disabled': {
        background: '#600101',
        color: 'white',
      },
      '&.yellow': {
        background: '#E3D026',
        color: 'black',
        '&:disabled': {
          color: 'black',
        },
      },
    },
    statusButton: {
      background: 'green',
      marginLeft: '1em',
      '&.yellow': {
        background: '#E3D026',
        '&:disabled': {
          color: 'black',
        },
      },
      '&:disabled': {
        color: 'white',
      },
      '& svg': {
        marginRight: '0.4em',
      },
    },
  })
);

export default function RecordToggleButton({
  className,
  globalAppState,
  appState,
}: {
  className?: string;
  globalAppState: any;
  appState: any;
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const hasInstanceId = globalAppState.instance?.id !== null && globalAppState.instance?.id !== undefined;
  const instanceStateTransition =
    globalAppState.instance?.state === 'Initializing ...' || globalAppState.instance?.state === 'Disconnecting ...';

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {appState?.isDirector ? (
        <>
          <Button
            onClick={() => {
              setOpen(true);
            }}
            className={clsx(classes.button, className, globalAppState.instance?.health === 'STARVING' ? 'yellow' : '')}
            disabled={instanceStateTransition}
          >
            {globalAppState.instance?.state === 'LIVE'
              ? `End Broadcast`
              : globalAppState.instance?.state || `${hasInstanceId ? `End` : `Start`} Broadcast`}
          </Button>
          {globalAppState.instance?.state === 'LIVE' && (
            <Button
              className={clsx(
                classes.statusButton,
                className,
                globalAppState.instance?.health === 'STARVING' ? 'yellow' : ''
              )}
              disabled={true}
              startIcon={<Visibility />}
            >
              {globalAppState.instance?.viewerCount}
            </Button>
          )}
        </>
      ) : (
        <>
          <Button
            className={clsx(classes.button, className, globalAppState.instance?.health === 'STARVING' ? 'yellow' : '')}
            disabled={true}
          >
            {globalAppState.instance?.state ? globalAppState.instance?.state : `OFFLINE`}
          </Button>
          {globalAppState.instance?.state === 'LIVE' && (
            <Button
              className={clsx(
                classes.statusButton,
                className,
                globalAppState.instance?.health === 'STARVING' ? 'yellow' : ''
              )}
              disabled={true}
              startIcon={<Visibility />}
            >
              {globalAppState.instance?.viewerCount}
            </Button>
          )}
        </>
      )}
      <ConfirmBroadcastDialog
        id="recordToggle-menu"
        title={`${hasInstanceId ? `End` : `Start`} Broadcast`}
        content={`Are you sure you want to ${hasInstanceId ? `end` : `start`} the broadcast stream?`}
        confirmtext={`${hasInstanceId ? `End` : `Start`} Broadcast`}
        keepMounted
        open={open}
        onClose={handleClose}
        action={'toggleRecord'}
      />
    </>
  );
}
