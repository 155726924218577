import React, { ChangeEvent, useState, FormEvent } from 'react';
import { useAppState } from '../../state';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {
  InputLabel,
  TextField,
  Theme,
  Typography,
  Button,
  Divider,
  Link,
  CircularProgress,
  Grid,
} from '@material-ui/core';
import IntroContainer from '../IntroContainer/IntroContainer';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../hooks/useQuery/useQuery';
import { isMobile } from '../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  errorMessage: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 0.2em',
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 1.5em',
    },
    '& svg': {
      marginRight: '0.4em',
    },
  },
  gutterBottom: {
    marginBottom: '1em',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0.5em',
    },
    whiteSpace: 'pre-line',
  },
  passcodeContainer: {
    marginBottom: '20px',
    minHeight: '80px',
    maxWidth: '45%',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flex: '1 1 100%',
      flexFlow: 'column',
      minHeight: '80px',
      maxWidth: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: '0px',
    },
    '&.no-height': {
      minHeight: '0px',
    },
  },
  passcodeResponse: {
    display: 'flex',
    flex: '1 1 100%',
  },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  togglePassLink: {
    color: '#ff6600',
    cursor: 'pointer',
  },
}));

export default function LoginPage() {
  const classes = useStyles();
  const { signIn, user, isAuthReady, appState } = useAppState();
  const history = useHistory();
  const query = useQuery();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passcode, setPasscode] = useState('');
  const [authError, setAuthError] = useState<Error | null>(null);
  const [displayPasscode, setDisplayPasscode] = useState<boolean>(false);
  const [isGettingAuth, setIsGettingAuth] = useState(false);

  const login = () => {
    setAuthError(null);
    setIsGettingAuth(true);
    signIn?.(passcode, username, password).catch(err => {
      setAuthError(err);
      setIsGettingAuth(false);
    });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login();
  };

  const handlePasscodeToggle = (e: any) => {
    e.preventDefault();

    setUsername('');
    setPassword('');
    setPasscode('');
    setAuthError(null);
    setDisplayPasscode(!displayPasscode);
  };

  if (user) {
    history.replace('/');
  }

  if (!isAuthReady) {
    return null;
  }

  return (
    <IntroContainer>
      <>
        {query.get('token') !== null || isGettingAuth ? (
          <Grid container justifyContent="center" alignItems="center" direction="column" style={{ height: '100%' }}>
            <div>
              <CircularProgress variant="indeterminate" />
            </div>
            <div>
              <Typography style={{ fontWeight: 'bold', fontSize: '16px' }}>Logging You In ...</Typography>
            </div>
          </Grid>
        ) : (
          <>
            <Typography variant="h5" className={classes.gutterBottom}>
              Login
            </Typography>
            <Typography className={classes.gutterBottom}>
              {!displayPasscode
                ? `Use your ${
                    appState.originDomain === 'mam' ? 'Money and Markets' : 'Banyan Hill'
                  } account to log in. \n\nIf you don't have an account or were given a passcode `
                : `If you cannot log in using your ${
                    appState.originDomain === 'mam' ? 'Money and Markets' : 'Banyan Hill'
                  } account or if you have been provided a passcode you can enter it here. \n\n Do not enter your ${
                    appState.originDomain === 'mam' ? 'Money and Markets' : 'Banyan Hill'
                  } account credentials. `}
              <Link onClick={handlePasscodeToggle} className={classes.togglePassLink}>
                {!displayPasscode ? `click here.` : `\n\nI don't have a passcode.`}
              </Link>
            </Typography>
            <Divider style={!isMobile ? { margin: '0 0 40px 0' } : { margin: '0 0 20px 0' }} />
            <form onSubmit={handleSubmit}>
              <Grid container justifyContent="space-between">
                {!displayPasscode ? (
                  <>
                    <div className={classes.passcodeContainer}>
                      <InputLabel shrink htmlFor="input-username">
                        Username
                      </InputLabel>
                      <TextField
                        id="input-username"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
                        type="text"
                        variant="outlined"
                        size="small"
                        placeholder="Email Address"
                        value={username}
                      />
                    </div>
                    <div className={classes.passcodeContainer}>
                      <InputLabel shrink htmlFor="input-password">
                        Password
                      </InputLabel>
                      <TextField
                        id="input-password"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                        type="password"
                        variant="outlined"
                        size="small"
                        placeholder="Password"
                        value={password}
                      />
                    </div>
                  </>
                ) : (
                  <div className={clsx(classes.passcodeContainer, 'no-height')}>
                    <InputLabel shrink htmlFor="input-passcode">
                      Passcode
                    </InputLabel>
                    <TextField
                      id="input-passcode"
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setPasscode(e.target.value)}
                      type="password"
                      variant="outlined"
                      size="small"
                      placeholder="Passcode"
                      value={passcode}
                    />
                  </div>
                )}
                <div className={classes.passcodeResponse}>
                  {authError &&
                    (authError.message === '' ? (
                      <>
                        <Typography variant="caption" className={classes.errorMessage}>
                          <ErrorOutlineIcon />
                          Unknown error or too frequent attempts.
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography variant="caption" className={classes.errorMessage}>
                          <ErrorOutlineIcon />
                          {authError.message.charAt(0).toUpperCase() + authError.message.slice(1).replace('_', ' ')}
                        </Typography>
                      </>
                    ))}
                </div>
              </Grid>
              <Grid container justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!passcode.length && !password.length}
                  className={classes.submitButton}
                >
                  Submit
                </Button>
              </Grid>
            </form>
          </>
        )}
      </>
    </IntroContainer>
  );
}
