import React, { useCallback, useRef, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Grid, Button, Tooltip } from '@material-ui/core';
import { useAppState } from '../../../state';
import { raiseHand } from '../../../state/api/api';
import { useEnqueueSnackbar } from '../../../hooks/useSnackbar/useSnackbar';
import LowerHandIcon from '../../../icons/LowerHandIcon';
import RaiseHandIcon from '../../../icons/RaiseHandIcon';
import FullScreenButton from '../../Buttons/FullScreenButton/FullScreenButton';
import ToggleParticipantWindowButton from '../../Buttons/ToggleParticipantWindow/ToggleParticipantWindowButton';
import VolumeControlButton from '../../Buttons/VolumeControlButton/VolumeControlButton';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useSyncContext from '../../../hooks/useSyncContext/useSyncContext';
import { isMobile } from '../../../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: 'white',
      bottom: 0,
      left: 0,
      right: 0,
      height: `${theme.footerHeight}px`,
      display: 'flex',
      padding: '0 1.43em',
      zIndex: 10,
      position: 'fixed',
      flex: '1 0 100%',
      [theme.breakpoints.down('sm')]: {
        height: `${theme.mobileFooterHeightViewer}px`,
      },
    },
    button: {
      background: theme.brand,
      color: 'white',
      '&:hover': {
        background: '#600101',
      },
    },
    roomName: {
      flex: 1,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  })
);

export default function PlayerMenuBar({
  globalAppState,
  roomName,
  disconnect,
}: {
  globalAppState: any;
  roomName?: string;
  disconnect: () => void;
}) {
  const classes = useStyles();
  const { appState, appDispatch } = useAppState();
  const [isHandRaised, setIsHandRaised] = useState(false);
  const lastClickTimeRef = useRef(0);
  const enqueueSnackbar = useEnqueueSnackbar();
  const { disconnect: chatDisconnect } = useChatContext();
  const { disconnect: syncDisconnect } = useSyncContext();

  const handleRaiseHand = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      raiseHand(appState.participantName, appState.eventName, !isHandRaised).then(() => {
        if (!isHandRaised) {
          enqueueSnackbar({
            headline: '',
            message: "Your request was sent! If the host accepts, you'll be able to share audio and video",
            variant: 'info',
          });
        }
        setIsHandRaised(!isHandRaised);
      });
    }
  }, [isHandRaised, appState.participantName, appState.eventName, enqueueSnackbar]);

  const disconnectFromEvent = () => {
    disconnect();
    appDispatch({ type: 'reset-state' });
    chatDisconnect();
    syncDisconnect();
  };

  return (
    <footer className={classes.container}>
      <Grid container justifyContent="space-around" alignItems="center">
        <Grid className={classes.roomName}>
          <Typography variant="body1">{roomName!.split(' | ')[0]}</Typography>
        </Grid>
        <Grid item>
          <Grid container justifyContent="center">
            {globalAppState.isRaiseHandEnabled && (
              <Tooltip title={isHandRaised ? 'Lower Hand' : 'Raise Hand'} placement="top">
                <Button onClick={handleRaiseHand}>{isHandRaised ? <LowerHandIcon /> : <RaiseHandIcon />}</Button>
              </Tooltip>
            )}
            <ToggleParticipantWindowButton />
            <span style={{ padding: '0 5px' }}>
              <FullScreenButton disabled={appState.activeTab !== 0} />
            </span>
            {!isMobile && <VolumeControlButton />}
          </Grid>
        </Grid>
        <Grid style={{ flex: 1 }}>
          <Grid container justifyContent="flex-end">
            <Button onClick={disconnectFromEvent} className={classes.button}>
              Leave
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
}
