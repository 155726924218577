import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import RecordToggleButton from '../Buttons/RecordToggleButton/RecordToggleButton';
import EndEventButton from '../Buttons/EndEventButton/EndEventButton';
import LeaveEventButton from '../Buttons/LeaveEventButton/LeaveEventButton';
import AdminMenu from './AdminMenu/AdminMenu';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Typography, Grid, Hidden } from '@material-ui/core';
import ToggleParticipantWindowButton from '../Buttons/ToggleParticipantWindow/ToggleParticipantWindowButton';
import { useAppState } from '../../state';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: 'white',
      bottom: 0,
      left: 0,
      right: 0,
      height: `${theme.footerHeight}px`,
      display: 'flex',
      padding: '0 1.43em',
      zIndex: 10,
      position: 'fixed',
      flex: '1 0 100%',
      [theme.breakpoints.down('sm')]: {
        height: `${theme.mobileFooterHeightHost}px`,
        padding: 0,
      },
    },
    screenShareBanner: {
      position: 'fixed',
      zIndex: 8,
      bottom: `${theme.footerHeight}px`,
      left: 0,
      right: 0,
      height: '104px',
      background: 'rgba(0, 0, 0, 0.5)',
      '& h6': {
        color: 'white',
      },
      '& button': {
        background: 'white',
        color: theme.brand,
        border: `2px solid ${theme.brand}`,
        margin: '0 2em',
        '&:hover': {
          color: '#600101',
          border: `2px solid #600101`,
          background: '#FFE9E7',
        },
      },
    },
    hideMobile: {
      display: 'initial',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  })
);

export default function MenuBar({ globalAppState }: { globalAppState: any }) {
  const classes = useStyles();
  const { room } = useVideoContext();
  const { appState } = useAppState();

  return (
    <>
      <footer className={classes.container}>
        <Grid container justifyContent="space-around" alignItems="center">
          <Hidden smDown>
            <Grid style={{ flex: 1 }}>
              <Typography variant="body1">{room!.name}</Typography>
            </Grid>
          </Hidden>
          <Grid item>
            <Grid container justifyContent="center">
              <ToggleParticipantWindowButton />
              <AdminMenu globalAppState={globalAppState} />
              <RecordToggleButton globalAppState={globalAppState} appState={appState} />
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid style={{ flex: 1 }}>
              <Grid container justifyContent="flex-end">
                <LeaveEventButton />
                {(appState.participantType === 'host' || appState.participantType === 'moderator') && (
                  <EndEventButton />
                )}
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </footer>
    </>
  );
}
