import React from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    messageInfoContainer: {
      display: 'flex',
      float: 'left',
      fontSize: '12px',
      color: '#606B85',
      padding: '0.75em 0.5em 0 0',
    },
    messageDate: {
      textTransform: 'uppercase',
      margin: '0 0.3em 0 0',
    },
    messageAuthor: {
      fontWeight: 'bold',
    },
    messageAuthorStatus: {
      fontWeight: 'bold',
      color: '#E22525',
    },
    isHostChat: {
      color: theme.brand,
    },
    isModeratorChat: {
      color: 'blue',
    },
    isAnnouncement: {
      color: '#FFCC00',
    },
  })
);

interface MessageInfoProps {
  author: string;
  authorStatus?: string;
  dateCreated: string;
  isLocalParticipant: boolean;
  isHost: boolean;
  isModerator: boolean;
  isAnnouncement: boolean;
}

const getFormattedAuthor = (author: string, isHost?: boolean, isModerator?: boolean) => {
  let authorArray = author?.split(' ');

  if (isHost || isModerator) return author;

  if (authorArray.length > 1) {
    return `${authorArray[0]} ${authorArray[authorArray.length - 1].charAt(0)}.`;
  } else {
    return author;
  }
};

export default function MessageInfo({
  author,
  authorStatus,
  dateCreated,
  isLocalParticipant,
  isHost,
  isModerator,
  isAnnouncement,
}: MessageInfoProps) {
  const classes = useStyles();

  return (
    <div className={classes.messageInfoContainer}>
      <div className={classes.messageDate}>{dateCreated}</div>
      <div
        className={clsx(classes.messageAuthor, {
          [classes.isHostChat]: isHost,
          [classes.isModeratorChat]: isModerator,
          [classes.isAnnouncement]: isAnnouncement,
        })}
      >
        {getFormattedAuthor(author, isHost, isModerator)}{' '}
        {authorStatus && <span className={classes.messageAuthorStatus}>{authorStatus}</span>}
      </div>
    </div>
  );
}
