export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  width: 1920,
  height: 1080,
  frameRate: 60,
};

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'TwilioVideoApp-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'TwilioVideoApp-selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'TwilioVideoApp-selectedVideoInput';

// This is used to store the current background settings in localStorage
export const SELECTED_BACKGROUND_SETTINGS_KEY = 'TwilioVideoApp-selectedBackgroundSettings';
export const BH_DOMAINS = [
  'https://tradekingslive.com',
  'https://banyanhilllive.com',
  'https://twilio-live-interactive-video-9687-2703-dev.twil.io',
];
export const MAM_DOMAINS = [
  'http://localhost:3000',
  'https://moneyandmarketslive.com',
  'https://twilio-live-interactive-video-9687-2703-stage.twil.io',
];
