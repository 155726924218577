import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useAppState } from '../../state';
import { Tabs, Tab, Box, Tooltip } from '@material-ui/core';

import ParticipantWindow from '../ParticipantWindow/ParticipantWindow';
import ChatWindow from '../ChatWindow/ChatWindow';
import BansWindow from '../BansWindow/BansWindow';
import useChatContext from '../../hooks/useChatContext/useChatContext';
import useSyncContext from '../../hooks/useSyncContext/useSyncContext';

import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import ChatBubbleOutlineOutlinedIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';
import CrownIcon from '../../icons/CrownIcon';
import AlertIconTriangle from '../../icons/AlertIconTriangle';
import axios from 'axios';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
      className={classes.fullHeightTabPanel}
      role="tabpanel"
      style={{ display: value !== index ? 'none' : 'flex' }}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box style={{ display: 'flex', flex: 1, flexFlow: 'column' }}>{children}</Box>
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabWindowContainer: {
      background: '#FFFFFF',
      zIndex: 9,
      flexDirection: 'column',
      borderLeft: '1px solid #E4E7E9',
      borderBottom: '1px solid #E4E7E9',
      minWidth: `${theme.rightDrawerWidth}px`,
      height: '100%',
      // [theme.breakpoints.down('sm')]: {
      //   // account for 1.85 AR video
      //   height: `calc(100% - ${window.innerWidth * 0.85 - theme.mobileFooterHeightViewer}px)`,
      // },
    },
    tabPanel: {
      maxWidth: `${theme.rightDrawerWidth - 1}px`,
    },
    iconTab: {
      [theme.breakpoints.up('sm')]: {
        minWidth: '60px',
      },
      '&.Mui-selected': {
        background: 'white',
      },
    },
    hide: {
      display: 'none',
    },
    fullHeightTabPanel: {
      display: 'flex',
      height: `calc(100% - ${theme.tabHeaderHeight}px)`,
      width: `${theme.rightDrawerWidth - 1}px`,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  })
);

export default function TabsWindow({
  globalAppState,
  globalAppStateMap,
  chatBans,
  eventBans,
  host,
  speakers,
  isPortrait,
  pubcode,
  externalSlug,
}: {
  globalAppState: any;
  globalAppStateMap: any;
  chatBans: any;
  eventBans: any;
  host: any;
  speakers: any;
  isPortrait: boolean;
  pubcode: string;
  externalSlug?: string;
}) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [isGettingSubscriptions, setIsGettingSubscriptions] = useState(false);
  const [subscriptionContent, setSubscriptionsContent] = useState<string>('');
  const { appState } = useAppState();
  const { isChatWindowOpen, setIsChatWindowOpen } = useChatContext();
  const { userDocument } = useSyncContext();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);

    // target chat tab
    if (newValue === 1) {
      setIsChatWindowOpen(true);
    } else {
      setIsChatWindowOpen(false);
    }
  };

  useEffect(() => {
    if (!(userDocument && 'is_free' in userDocument?.data && (userDocument?.data as any).is_free === true)) return;

    if (!externalSlug) return;

    async function getSubscriptionCopy() {
      return await axios.post(
        `https://${appState.originDomain === 'mam' ? 'moneyandmarkets' : 'banyanhill'}.com/graphql`,
        {
          query: `
          {
            external(id: "/externals/${externalSlug}/subscription-tab", idType: URI) {
              content
              databaseId
              date
              externalSettings {
                headerBlurb
              }
              slug
              title
            }
          }
        `,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    }

    if (!subscriptionContent && !isGettingSubscriptions) {
      setIsGettingSubscriptions(true);
      getSubscriptionCopy().then(subs => {
        setSubscriptionsContent((subs.data as any)?.data.external.content);
        setIsGettingSubscriptions(false);
      });
    }
  }, [externalSlug, userDocument, subscriptionContent, isGettingSubscriptions, appState.originDomain]);

  return (
    <aside
      className={clsx(classes.tabWindowContainer, 'tabWindowContainer', {
        [classes.hide]: !appState.isParticipantWindowOpen && !isChatWindowOpen,
      })}
    >
      <Tabs
        style={{ color: 'black', background: 'rgba(0,0,0,0.04)' }}
        value={value}
        onChange={handleChange}
        aria-label="Window Tabs"
        variant="scrollable"
        scrollButtons="auto"
      >
        {(appState.participantType === 'host' || appState.participantType === 'moderator') && (
          <Tooltip title="Participants" placement="top">
            <Tab
              icon={<PersonOutlineOutlinedIcon />}
              aria-label="Participants"
              {...a11yProps(0)}
              className={classes.iconTab}
            />
          </Tooltip>
        )}
        <Tooltip title="Chat" placement="top">
          <Tab
            icon={<ChatBubbleOutlineOutlinedIcon />}
            aria-label="Chat"
            {...a11yProps(1)}
            className={classes.iconTab}
          />
        </Tooltip>
        ,
        <Tooltip title="Announcements" placement="top">
          <Tab
            icon={pubcode === 'TKO' ? <CrownIcon /> : <AlertIconTriangle />}
            aria-label="Announcements"
            {...a11yProps(2)}
            className={classes.iconTab}
          />
        </Tooltip>
        {(appState.participantType === 'host' || appState.participantType === 'moderator') && (
          <Tooltip title="Bans" placement="top">
            <Tab icon={<BlockOutlinedIcon />} aria-label="Bans" {...a11yProps(3)} className={classes.iconTab} />
          </Tooltip>
        )}
      </Tabs>
      {(appState.participantType === 'host' || appState.participantType === 'moderator') && (
        <TabPanel value={value} index={0}>
          <ParticipantWindow chatBans={chatBans} eventBans={eventBans} host={host} speakers={speakers} />
        </TabPanel>
      )}
      {!(userDocument && 'is_free' in userDocument?.data && (userDocument?.data as any).is_free === true)
        ? [
            <TabPanel
              value={value}
              index={appState.participantType === 'host' || appState.participantType === 'moderator' ? 1 : 0}
            >
              {value === (appState.participantType === 'host' || appState.participantType === 'moderator' ? 1 : 0) && (
                <ChatWindow
                  isAttachmentEnabled={globalAppState.isAttachmentEnabled}
                  isChatEnabled={globalAppState.isChatEnabled}
                  isAnnouncemenTab={false}
                  appState={appState}
                  chatBans={chatBans}
                  eventBans={eventBans}
                  globalAppState={globalAppState}
                  globalAppStateMap={globalAppStateMap}
                  pubcode={pubcode}
                />
              )}
            </TabPanel>,
            <TabPanel
              value={value}
              index={appState.participantType === 'host' || appState.participantType === 'moderator' ? 2 : 1}
            >
              {value === (appState.participantType === 'host' || appState.participantType === 'moderator' ? 2 : 1) && (
                <ChatWindow
                  isAttachmentEnabled={false}
                  isChatEnabled={true}
                  isAnnouncemenTab={true}
                  appState={appState}
                  chatBans={chatBans}
                  eventBans={eventBans}
                  globalAppState={globalAppState}
                  globalAppStateMap={globalAppStateMap}
                  pubcode={pubcode}
                />
              )}
            </TabPanel>,
          ]
        : [
            <TabPanel value={value} index={0}>
              <div dangerouslySetInnerHTML={{ __html: subscriptionContent }} />
            </TabPanel>,
            <TabPanel value={value} index={1}>
              <div dangerouslySetInnerHTML={{ __html: subscriptionContent }} />
            </TabPanel>,
          ]}
      {(appState.participantType === 'host' || appState.participantType === 'moderator') && (
        <TabPanel value={value} index={3}>
          <BansWindow chatBans={chatBans} eventBans={eventBans} />
        </TabPanel>
      )}
    </aside>
  );
}
