import React from 'react';

export default function BackArrowIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.0086 9.90959L5.00171 9.95991L5.0018 10.0406L5.01284 10.1108L5.02195 10.1389C5.05447 10.2517 5.12851 10.3475 5.22664 10.4114L9.059 13.8548C9.27426 14.0484 9.62326 14.0484 9.83852 13.8548C10.0299 13.6828 10.0511 13.4158 9.9023 13.2226L9.83852 13.154L6.88196 10.495L14.4873 10.4956C14.7704 10.4956 15 10.2737 15 10C15 9.7567 14.8186 9.55435 14.5794 9.51239L14.4873 9.5044L6.88296 9.504L9.83852 6.84604C10.0299 6.674 10.0511 6.40695 9.9023 6.2138L9.83852 6.14516C9.64718 5.97312 9.35017 5.954 9.13534 6.08781L9.059 6.14516L5.22664 9.58863C5.12851 9.65251 5.05447 9.74834 5.0205 9.86069L5.0086 9.90959Z"
        fill="#121C2D"
      />
    </svg>
  );
}
