import React, { useEffect, useRef, useState, useCallback, useLayoutEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CircularProgress, Grid, Tooltip, Typography } from '@material-ui/core';
// import PlayerControlBar from './PlayerControlBar/PlayerControlBar';
// import { Player as TwilioPlayer } from '@twilio/live-player-sdk';
import useChatContext from '../../hooks/useChatContext/useChatContext';
import useSyncContext from '../../hooks/useSyncContext/useSyncContext';
import usePlayerContext from '../../hooks/usePlayerContext/usePlayerContext';
import { useEnqueueSnackbar } from '../../hooks/useSnackbar/useSnackbar';
import { usePlayerState } from '../../hooks/usePlayerState/usePlayerState';
import { useAppState } from '../../state';
import { useGlobalAppStateMap } from '../../hooks/useGlobalAppState/useGlobalAppState';
import { reconnectViewer } from '../../state/api/api';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import Sync from '@material-ui/icons/Sync';

// TwilioPlayer.telemetry.subscribe(data => {
//   const method = data.name === 'error' ? 'error' : 'log';
//   console[method](`[${data.type}.${data.name}] => ${JSON.stringify(data)}`);
// });

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    video: {
      width: '100%',
      height: '100%',
      backgroundColor: 'black',
    },
    qualityBanner: {
      position: 'absolute',
      zIndex: 8,
      top: 0,
      right: 0,
      padding: '10px',
      color: '#fff',
      background: 'rgba(0,0,0,0.4)',
      width: '100%',
      '& svg': {
        margin: '0 10px',
        cursor: 'pointer',
      },
    },
    loadingBanner: {
      position: 'absolute',
      zIndex: 8,
      color: '#fff',
      top: '50%',
      transform: 'translateY(-50%)',
      '& svg': {
        color: 'rgba(255,255,255,0.5)',
      },
    },
  })
);

function Player({ eventBans }: { eventBans: any }) {
  const classes = useStyles();
  const { disconnect: chatDisconnect } = useChatContext();
  const { disconnect: syncDisconnect } = useSyncContext();
  const videoElRef = useRef<HTMLVideoElement>(null!);
  const { player, connect: playerConnect } = usePlayerContext();
  const state = usePlayerState();
  const { appState, appDispatch } = useAppState();
  const globalAppState = useGlobalAppStateMap();
  const enqueueSnackbar = useEnqueueSnackbar();
  const [welcomeMessageDisplayed, setWelcomeMessageDisplayed] = useState(false);
  const [bufferSeconds, setBufferSeconds] = useState(0);
  const [isConnecting, setIsConnecting] = useState(false);

  const handleReconnect = useCallback(async () => {
    if (isConnecting) return;
    setIsConnecting(true);
    setBufferSeconds(0);

    await reconnectViewer(appState.participantName, appState.eventName).then(
      async data => await playerConnect(data.data.token).then(() => console.log('reconnected'))
    );
  }, [appState.participantName, appState.eventName, playerConnect, isConnecting]);

  useLayoutEffect(() => {
    let bufferInterval: any;

    if (player && state === 'Ready') {
      appDispatch({ type: 'set-is-loading', isLoading: false });

      player.attachHTMLVideoElement(videoElRef.current);
      player.play();

      // @ts-ignore
      gtag('event', 'event_play', {
        eventCategory: 'Video',
        eventAction: 'Event Play',
        eventLabel: appState.eventName,
      });
    }

    if (player && state === 'Buffering') {
      bufferInterval = setInterval(() => {
        setBufferSeconds(prevSeconds => prevSeconds + 1);

        // @ts-ignore
        gtag('event', 'event_buffer', {
          eventCategory: 'Video',
          eventAction: 'Event Buffering',
          eventLabel: appState.eventName,
        });
      }, 1000);

      return () => clearInterval(bufferInterval);
    }

    if (player && state === 'Playing') {
      setBufferSeconds(0);
      setIsConnecting(false);
      if (bufferInterval) {
        clearInterval(bufferInterval);
      }

      return () => {
        setBufferSeconds(0);
      };
    }

    if (player && state === 'Idle') {
      console.log('broadcast: ', globalAppState.instance?.health || 'STOPPED');
      if (globalAppState.instance?.health === 'HEALTHY') {
        if (!player.core.state.path && player.core.paused) {
          handleReconnect();
        }

        if (player.core.state.path && player.core.paused) {
          player.load(player.core.state.path);
        }
      }

      function heartbeatTimer() {
        // @ts-ignore
        gtag('event', 'heartbeat', {
          eventCategory: 'Video',
          eventAction: 'Heartbeat',
          eventLabel: appState.eventName,
        });
      }

      setInterval(heartbeatTimer, 1 * 60 * 1000);
    }

    if (player && state === 'Ended') {
      //TODO: Trigger disconnect message
      if (player.core.state.path && player.core.paused) {
        player.load(player.core.state.path);
      }

      // @ts-ignore
      gtag('event', 'event_end', {
        eventCategory: 'Video',
        eventAction: 'Event Ended',
        eventLabel: appState.eventName,
      });
    }
  }, [player, appDispatch, handleReconnect, state, globalAppState.instance, appState.eventName]);

  useEffect(() => {
    if (!welcomeMessageDisplayed) {
      setWelcomeMessageDisplayed(true);
      enqueueSnackbar({
        headline: 'Welcome!',
        message: "You're now in the audience - you'll be unable to share audio or video unless invited by the host.",
        variant: 'info',
      });
    }
  }, [enqueueSnackbar, welcomeMessageDisplayed]);

  // handle Event Bans
  useEffect(() => {
    const disconnectFromEvent = () => {
      enqueueSnackbar({
        headline: 'Error!',
        message: 'You have been removed from the event.',
        variant: 'error',
      });

      player?.pause();
      appDispatch({ type: 'reset-state' });
      chatDisconnect();
      syncDisconnect();
    };

    if (player && eventBans.length > 0 && eventBans.includes(appState.participantName)) {
      disconnectFromEvent();
    }
  }, [eventBans, player, appState.participantName, enqueueSnackbar, appDispatch, chatDisconnect, syncDisconnect]);

  return (
    <>
      {(bufferSeconds >= 2 || state === 'idle') && (
        <>
          <Grid container justifyContent="flex-end" alignItems="center" className={classes.qualityBanner}>
            <Tooltip title={`Connection Issue Detected`} placement="top">
              <InfoOutlined />
            </Tooltip>
            {(bufferSeconds >= 10 || state === 'idle') && (
              <>
                <Tooltip title={`Reconnect`} placement="top">
                  <Sync onClick={handleReconnect} />
                </Tooltip>
              </>
            )}
          </Grid>
        </>
      )}
      {bufferSeconds >= 2 && state === 'buffering' && !isConnecting && (
        <>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            className={classes.loadingBanner}
          >
            <CircularProgress variant="indeterminate" />
          </Grid>
        </>
      )}
      {isConnecting && (
        <>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            className={classes.loadingBanner}
          >
            <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '16px', marginBottom: '10px' }}>
              Connecting ...
            </Typography>
            <CircularProgress variant="indeterminate" />
          </Grid>
        </>
      )}
      {/* <PlayerControlBar player={player} /> */}
      <video
        id="playerContainer"
        className={classes.video}
        ref={videoElRef}
        playsInline
        poster={`https://banyanhill.s3.amazonaws.com/tradeRoom/${
          appState.eventName.split(' | ')[1]?.split(',')[0]
        }/offline.jpg`}
      ></video>
    </>
  );
}

export default React.memo(Player);
