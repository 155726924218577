import React from 'react';

import { makeStyles, Button, Tooltip } from '@material-ui/core';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import fscreen from 'fscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';

import useFullScreenToggle from '../../../hooks/useFullScreenToggle/useFullScreenToggle';

export const ANIMATION_DURATION = 700;

const useStyles = makeStyles({
  active: {
    textDecoration: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
  iconContainer: {
    position: 'relative',
    display: 'flex',
  },
  circle: {
    width: '10px',
    height: '10px',
    backgroundColor: '#D61F1F',
    borderRadius: '50%',
    position: 'absolute',
    top: '-3px',
    left: '13px',
    opacity: 0,
    transition: `opacity ${ANIMATION_DURATION * 0.5}ms ease-in`,
  },
  hasRaisedHands: {
    opacity: 1,
  },
  ring: {
    border: '3px solid #D61F1F',
    borderRadius: '30px',
    height: '14px',
    width: '14px',
    position: 'absolute',
    left: '11px',
    top: '-5px',
    opacity: 0,
  },
  animateRing: {
    animation: `$expand ${ANIMATION_DURATION}ms ease-out`,
    animationIterationCount: 1,
  },
  '@keyframes expand': {
    '0%': {
      transform: 'scale(0.1, 0.1)',
      opacity: 0,
    },
    '50%': {
      opacity: 1,
    },
    '100%': {
      transform: 'scale(1.4, 1.4)',
      opacity: 0,
    },
  },
});

export default function ToggleFullscreenButton(props: { disabled?: boolean }) {
  const classes = useStyles();
  const [isFullScreen, toggleFullScreen] = useFullScreenToggle();

  return fscreen.fullscreenEnabled ? (
    <>
      <Tooltip title={isFullScreen ? 'Exit Full Screen' : 'Full Screen'} placement="top">
        <span>
          <Button className={isFullScreen ? classes.active : ''} aria-label={`full screen`} onClick={toggleFullScreen}>
            {isFullScreen ? <FullscreenExitIcon fontSize="large" /> : <FullscreenIcon fontSize="large" />}
          </Button>
        </span>
      </Tooltip>
    </>
  ) : null;
}
