import { useEffect, useState } from 'react';
import useSyncContext from '../useSyncContext/useSyncContext';

export function useViewersMap() {
  const { viewersMap } = useSyncContext();
  const [viewers, setViewers] = useState<string[]>([]);

  useEffect(() => {
    const pageHandler = (paginator: any) => {
      setViewers((prevViewers: any) => {
        if (prevViewers.length > 0) {
          return [...prevViewers, ...paginator.items.map((item: any) => item.key)];
        } else {
          return [...paginator.items.map((item: any) => item.key)];
        }
      });

      return paginator.hasNextPage ? paginator.nextPage().then(pageHandler) : null;
    };

    if (viewersMap) {
      viewersMap
        .getItems({ pageSize: 100, limit: 5000 })
        .then(pageHandler)
        .catch(error => {
          console.error('Map getItems() failed', error);
        });

      const handleItemAdded = (args: any) => {
        setViewers(prevViewers => [...prevViewers, args.item.key]);
      };

      const handleItemRemoved = (args: any) => {
        setViewers(prevViewers => prevViewers.filter(i => i !== args.key));
      };

      viewersMap.on('itemAdded', handleItemAdded);
      viewersMap.on('itemRemoved', handleItemRemoved);

      return () => {
        viewersMap.off('itemAdded', handleItemAdded);
        viewersMap.off('itemRemoved', handleItemRemoved);
      };
    }
  }, [viewersMap]);

  return viewers;
}
