import React from 'react';

export default function CreateEventIcon() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#EBF4FF" />
      <path
        d="M15.2656 27.6719H24.7266C26.4219 27.6719 27.2969 26.7969 27.2969 25.125V15.6172C27.2969 13.9453 26.4219 13.0703 24.7266 13.0703H15.2656C13.5781 13.0703 12.6953 13.9375 12.6953 15.6172V25.125C12.6953 26.7969 13.5781 27.6719 15.2656 27.6719ZM15.3672 26.1172C14.6484 26.1172 14.25 25.7422 14.25 24.9844V15.7578C14.25 15 14.6484 14.625 15.3672 14.625H24.625C25.3359 14.625 25.7422 15 25.7422 15.7578V24.9844C25.7422 25.7422 25.3359 26.1172 24.625 26.1172H15.3672ZM17.1172 21.0938H19.2656V23.2422C19.2656 23.6797 19.5703 23.9844 19.9922 23.9844C20.4297 23.9844 20.7422 23.6797 20.7422 23.2422V21.0938H22.8906C23.3203 21.0938 23.625 20.7891 23.625 20.3672C23.625 19.9297 23.3203 19.6172 22.8906 19.6172H20.7422V17.4766C20.7422 17.0312 20.4297 16.7344 19.9922 16.7344C19.5703 16.7344 19.2656 17.0391 19.2656 17.4766V19.6172H17.1172C16.6797 19.6172 16.375 19.9297 16.375 20.3672C16.375 20.7891 16.6797 21.0938 17.1172 21.0938Z"
        fill="#0263E0"
      />
    </svg>
  );
}
