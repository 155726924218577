import { useEffect, useState } from 'react';
import useSyncContext from '../useSyncContext/useSyncContext';

export function useEventBansMap() {
  const { eventBansMap } = useSyncContext();
  const [eventBans, setEventBans] = useState<string[]>([]);

  useEffect(() => {
    if (eventBansMap) {
      // Sets the list on load. Limiting to first 100 viewers who are banned from event
      eventBansMap.getItems({ pageSize: 100 }).then(paginator => {
        setEventBans(paginator.items.map(item => item.key));
      });

      const handleItemAdded = (args: any) => {
        setEventBans(prevEventBans => [...prevEventBans, args.item.key]);
      };

      const handleItemRemoved = (args: any) => {
        setEventBans(prevEventBans => prevEventBans.filter(i => i !== args.key));
      };

      eventBansMap.on('itemAdded', handleItemAdded);
      eventBansMap.on('itemRemoved', handleItemRemoved);

      return () => {
        eventBansMap.off('itemAdded', handleItemAdded);
        eventBansMap.off('itemRemoved', handleItemRemoved);
      };
    }
  }, [eventBansMap]);

  return eventBans;
}
