import React from 'react';

export default function MoneyLogo({ className }: { className?: string }) {
  return (
    <div className={className}>
      <img
        alt="Money and Markets"
        src="https://moneyandmarkets.com/wp-content/uploads/2020/03/MAM_Favicon.png"
        width="80"
        height="80"
      />
    </div>
  );
}
