import { useEffect, useState } from 'react';
// import { Player as TwilioPlayer } from '@twilio/live-player-sdk';
import usePlayerContext from '../usePlayerContext/usePlayerContext';

export function usePlayerState() {
  const { player } = usePlayerContext();
  const [state, setState] = useState();

  useEffect(() => {
    if (player) {
      const setPlayerState = () => {
        setState((player as any).core.state.state);
      };

      if (player.core) {
        setPlayerState();
      }

      // @ts-ignore
      if (!window.IVSPlayer) return;

      // @ts-ignore
      const { ErrorType, PlayerEventType, PlayerState } = window.IVSPlayer;
      if (PlayerState) {
        // https://github.com/aws-samples/amazon-ivs-player-web-sample/blob/master/samples/cloud-player/cloud-player.ts
        for (let playerState of Object.values(PlayerState)) {
          player.addEventListener(playerState, () => {
            setPlayerState();
          });
        }
      }

      if (PlayerEventType) {
        player.addEventListener(PlayerEventType.ERROR, (error: any) => {
          const statusTooManyRequests = 429;
          if (error.type === ErrorType.NOT_AVAILABLE && error.code === statusTooManyRequests) {
            console.error('Concurrent-viewer limit reached', error);
          } else {
            console.error('ERROR', error);
          }
        });
      }

      // // @ts-ignore
      //       player.addEventListener(PlayerEventType.INITIALIZED, () => {
      //           console.log('INITIALIZED');
      //       });
      // // @ts-ignore
      //       player.addEventListener(PlayerEventType.QUALITY_CHANGED, (quality) => {
      //           console.log('QUALITY_CHANGED', quality);
      //       });
      //       // This event fires when text cues are encountered, such as captions or subtitles
      // // @ts-ignore
      //       player.addEventListener(PlayerEventType.TEXT_CUE, (cue) => {
      //           console.log('TEXT_CUE', cue.startTime, cue.text);
      //       });

      //       // This event fires when embedded Timed Metadata is encountered
      // // @ts-ignore
      //       player.addEventListener(PlayerEventType.TEXT_METADATA_CUE, (cue) => {
      //           console.log('Timed metadata', cue.text);
      //       });

      // player.on(TwilioPlayer.Event.StateChanged, setPlayerState);

      return () => {
        // remove event listeners
        if (PlayerState) {
          // https://github.com/aws-samples/amazon-ivs-player-web-sample/blob/master/samples/cloud-player/cloud-player.ts
          for (let playerState of Object.values(PlayerState)) {
            player.removeEventListener(playerState, () => {
              setPlayerState();
            });
          }
        }

        if (PlayerEventType) {
          player.removeEventListener(PlayerEventType.ERROR, (error: any) => {
            const statusTooManyRequests = 429;
            if (error.type === ErrorType.NOT_AVAILABLE && error.code === statusTooManyRequests) {
              console.error('Concurrent-viewer limit reached', error);
            } else {
              console.error('ERROR', error);
            }
          });
        }
      };
    }
  }, [player]);

  return state;
}
