import { produce } from 'immer';

export enum ActiveScreen {
  ParticipantNameScreen,
  CreateOrJoinScreen,
  CreateNewEventScreen,
  JoinHostEventScreen,
  SpeakerOrViewerScreen,
  JoinEventNameScreen,
  DeviceSelectionScreen,
}

export type appActionTypes =
  | { type: 'set-active-screen'; activeScreen: ActiveScreen }
  | { type: 'set-participant-name'; participantName: string }
  | { type: 'set-participant-type'; participantType: appStateTypes['participantType'] }
  | { type: 'set-event-name'; eventName: string }
  | { type: 'set-room-sid'; roomSid: string }
  | { type: 'set-is-loading'; isLoading: boolean }
  | { type: 'set-has-speaker-invite'; hasSpeakerInvite: boolean }
  | { type: 'reset-state' }
  | { type: 'set-notes-update'; hasNotesUpdate: boolean }
  | { type: 'set-is-participant-window-open'; isParticipantWindowOpen: boolean }
  | { type: 'set-active-tab'; activeTab: number }
  | { type: 'set-origin'; originDomain: string }
  | { type: 'set-is-director'; isDirector: boolean };

export interface appStateTypes {
  activeScreen: ActiveScreen;
  participantType: 'host' | 'guest' | 'speaker' | 'moderator' | 'viewer' | null;
  participantName: string;
  eventName: string;
  roomSid: string;
  mediaError: Error | null;
  isLoading: boolean;
  hasSpeakerInvite: boolean;
  hasNotesUpdate: boolean;
  isParticipantWindowOpen: boolean;
  activeTab: number;
  originDomain: string;
  isDirector: boolean;
}

export const initialAppState: appStateTypes = {
  activeScreen: ActiveScreen.ParticipantNameScreen,
  participantType: null,
  participantName: '',
  eventName: '',
  roomSid: '',
  mediaError: null,
  isLoading: false,
  hasSpeakerInvite: false,
  hasNotesUpdate: false,
  isParticipantWindowOpen: true,
  activeTab: 0,
  originDomain: '',
  isDirector: false,
};

export const appReducer = produce((draft: appStateTypes, action: appActionTypes) => {
  switch (action.type) {
    case 'set-participant-name':
      draft.participantName = action.participantName;
      break;

    case 'set-event-name':
      draft.eventName = action.eventName;
      break;

    case 'set-room-sid':
      draft.roomSid = action.roomSid;
      break;

    case 'set-active-screen':
      draft.activeScreen = action.activeScreen;
      break;

    case 'set-is-loading':
      draft.isLoading = action.isLoading;
      break;

    case 'set-active-tab':
      draft.activeTab = action.activeTab;
      break;

    case 'set-has-speaker-invite':
      // Ignore this action when connecting to a room
      if (!draft.isLoading) {
        draft.hasSpeakerInvite = action.hasSpeakerInvite;
        if (action.hasSpeakerInvite) {
          draft.activeScreen = ActiveScreen.DeviceSelectionScreen;
        }
      }
      break;

    case 'reset-state':
      // Don't reset state while transitioning to a room
      if (!draft.hasSpeakerInvite) {
        return initialAppState;
      }
      break;

    case 'set-notes-update':
      draft.hasNotesUpdate = action.hasNotesUpdate;
      break;

    case 'set-is-participant-window-open':
      draft.isParticipantWindowOpen = action.isParticipantWindowOpen;
      break;

    case 'set-origin':
      draft.originDomain = action.originDomain;
      break;

    case 'set-is-director':
      draft.isDirector = action.isDirector;
      break;

    case 'set-participant-type':
      draft.participantType = action.participantType;
      switch (action.participantType) {
        case 'host':
          draft.activeScreen = ActiveScreen.CreateNewEventScreen;
          break;
        case 'guest':
          draft.activeScreen = ActiveScreen.JoinHostEventScreen;
          break;
        case 'moderator':
          // do nothing
          break;
        case 'speaker':
        case 'viewer':
        case null:
          draft.activeScreen = ActiveScreen.JoinEventNameScreen;
          break;
      }
      break;
  }
});
