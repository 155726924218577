import React from 'react';
import { styled, Theme } from '@material-ui/core/styles';

import TabsScreen from './components/TabsScreen/TabsScreen';
import PreJoinScreens from './components/PreJoinScreens/PreJoinScreens';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import RecordingNotifications from './components/RecordingNotifications/RecordingNotifications';

import useHeight from './hooks/useHeight/useHeight';
import usePlayerContext from './hooks/usePlayerContext/usePlayerContext';
import useRoomState from './hooks/useRoomState/useRoomState';
import { useAppState } from './state';

// @ts-ignore
window.Buffer = window.Buffer || require('buffer').Buffer;

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: '1fr auto',
});

const Main = styled('main')(({ theme }: { theme: Theme }) => ({
  overflow: 'hidden',
  display: 'flex',
  paddingBottom: `${theme.footerHeight}px`, // Leave some space for the footer
  [theme.breakpoints.down('sm')]: {
    paddingBottom: `${theme.mobileFooterHeightViewer}px`, // Leave some space for the footer
  },
  background: 'white',
}));

export default function App() {
  const roomState = useRoomState();
  const { player } = usePlayerContext();
  const { appState } = useAppState();

  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();

  return (
    <Container style={{ height }}>
      {((roomState === 'disconnected' && !player) || appState.hasSpeakerInvite) && <PreJoinScreens />}

      {roomState !== 'disconnected' && (
        <Main>
          <ReconnectingNotification />
          <RecordingNotifications />
          <TabsScreen hasPlayer={false} />
        </Main>
      )}

      {player && (
        <Main>
          <TabsScreen hasPlayer={true} />
        </Main>
      )}
    </Container>
  );
}
