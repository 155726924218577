import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';
import { useAppState } from '../../state';
import { deleteStream, joinStreamAsViewer, connectViewerToPlayer } from '../../state/api/api';
import useChatContext from '../../hooks/useChatContext/useChatContext';
import useSyncContext from '../../hooks/useSyncContext/useSyncContext';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import usePlayerContext from '../../hooks/usePlayerContext/usePlayerContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: 'white',
      marginLeft: '1em',
      '&:hover': {
        background: '#600101',
      },
    },
  })
);

export interface ConfirmEventDialogProps {
  id: string;
  title: string;
  content: string;
  confirmtext: string;
  keepMounted: boolean;
  open: boolean;
  onClose: (value?: string) => void;
  action: any;
}

export default function ConfirmEventDialog(props: ConfirmEventDialogProps) {
  const classes = useStyles();
  const { room } = useVideoContext();
  const { disconnect: chatDisconnect } = useChatContext();
  const { registerUserDocument, disconnect: syncDisconnect } = useSyncContext();
  const { connect: playerConnect } = usePlayerContext();
  const { appState, appDispatch } = useAppState();
  const { onClose, open, ...other } = props;

  const handleCancel = () => {
    onClose();
  };

  const handleOk = (action: string) => {
    switch (action) {
      case 'switchToViewer': {
        switchToViewer();
        break;
      }

      case 'leaveEvent': {
        leaveEvent();
        break;
      }

      case 'disconnect': {
        disconnect();
        break;
      }
    }
  };

  async function disconnect() {
    room!.emit('setPreventAutomaticJoinStreamAsViewer');
    room!.disconnect();
    chatDisconnect();
    syncDisconnect();
    appDispatch({ type: 'reset-state' });
    await deleteStream(appState.eventName);
  }

  async function switchToViewer() {
    const { data } = await joinStreamAsViewer(appState.participantName, appState.eventName);
    await playerConnect(data.token);
    await connectViewerToPlayer(appState.participantName, appState.eventName);
    registerUserDocument(data.sync_object_names.user_document);
    room!.emit('setPreventAutomaticJoinStreamAsViewer');
    room!.disconnect();
  }

  function leaveEvent() {
    room!.emit('setPreventAutomaticJoinStreamAsViewer');
    room!.disconnect();
    chatDisconnect();
    syncDisconnect();
    appDispatch({ type: 'reset-state' });
  }

  return (
    <Dialog maxWidth="xs" open={open} {...other}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1" dangerouslySetInnerHTML={{ __html: props.content }}></Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleOk(props.action);
          }}
          className={clsx(classes.button)}
        >
          {props.confirmtext}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
