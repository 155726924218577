import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    messageContainer: {
      wordBreak: 'break-word',
      hyphens: 'auto',
      whiteSpace: 'pre-wrap',
    },
  })
);

interface TextMessageProps {
  body: string;
}

export default function TextMessage({ body }: TextMessageProps) {
  const classes = useStyles();

  return (
    <div className={classes.messageContainer}>
      <div dangerouslySetInnerHTML={{ __html: body }} />
    </div>
  );
}
