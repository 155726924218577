import React from 'react';

export default function SpeakerIcon() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#EBF4FF" />
      <path
        d="M20 22.0889C21.9863 22.0889 23.375 20.6035 23.375 18.4766V13.0098C23.375 10.8828 21.9863 9.39746 20 9.39746C18.0225 9.39746 16.625 10.8828 16.625 13.0098V18.4766C16.625 20.6035 18.0225 22.0889 20 22.0889ZM20 20.4629C18.9717 20.4629 18.3213 19.6719 18.3213 18.5117V12.9746C18.3213 11.8145 18.9717 11.0234 20 11.0234C21.0283 11.0234 21.6787 11.8145 21.6787 12.9746V18.5117C21.6787 19.6719 21.0283 20.4629 20 20.4629ZM15.957 28.7861H24.043C24.5088 28.7861 24.8955 28.4082 24.8955 27.9512C24.8955 27.4854 24.5088 27.1162 24.043 27.1162H20.8174V25.332C24.2891 24.998 26.7148 22.5107 26.7148 18.8721V17.1406C26.7148 16.6748 26.3457 16.3145 25.8799 16.3145C25.4141 16.3145 25.0273 16.6748 25.0273 17.1406V18.8105C25.0273 21.8076 22.9971 23.7764 20 23.7764C17.0029 23.7764 14.9727 21.8076 14.9727 18.8105V17.1406C14.9727 16.6748 14.5947 16.3145 14.1289 16.3145C13.6631 16.3145 13.2852 16.6748 13.2852 17.1406V18.8721C13.2852 22.5107 15.7109 24.998 19.1826 25.332V27.1162H15.957C15.4912 27.1162 15.1045 27.4854 15.1045 27.9512C15.1045 28.4082 15.4912 28.7861 15.957 28.7861Z"
        fill="#0263E0"
      />
    </svg>
  );
}
