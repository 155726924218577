import React, { useState } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import ConfirmEventDialog from '../../ConfirmEventDialog/ConfirmEventDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: 'white',
      marginLeft: '1em',
      '&:hover': {
        background: '#600101',
      },
    },
  })
);

export default function EndCallButton(props: { className?: string }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { room } = useVideoContext();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        className={clsx(classes.button, props.className)}
        data-cy-disconnect
      >
        End Event
      </Button>
      <ConfirmEventDialog
        id="endEvent-menu"
        title={`End Event?`}
        content={`Are you sure you want to end the <strong>${
          room!.name.split(' | ')[0]
        }</strong> event? You will not be able to
        re-join <strong>${room!.name.split(' | ')[0]}</strong> once ended.`}
        confirmtext={`End Event`}
        keepMounted
        open={open}
        onClose={handleClose}
        action={'disconnect'}
      />
    </>
  );
}
