import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { SpeakerMenu } from './SpeakerMenu/SpeakerMenu';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

interface SpeakersListProps {
  chatBans: any;
  eventBans: any;
  host?: string;
  speakers: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontWeight: 'bold',
      padding: '0.8em 0',
      color: theme.brand,
    },
    speakersListContainer: {
      padding: '0.4em 1em',
      overflow: 'auto',
      flexShrink: 0,
      maxHeight: 'calc(25% - 28px)', //participantWindowHeader is 56px
      minHeight: '55px',
      borderBottom: '0.1em solid #CACDD8',
    },
    speakerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
      '& p': {
        padding: '0.75em 0',
      },
      '& button': {
        display: 'none',
      },
      '&:hover button': {
        display: 'inline-flex',
      },
    },
  })
);

export default function SpeakersList({ chatBans, eventBans, host, speakers }: SpeakersListProps) {
  const { room } = useVideoContext();
  const localParticipant = room?.localParticipant;

  const classes = useStyles();

  return (
    <div className={classes.speakersListContainer}>
      <div className={classes.header}>{`Speakers (${speakers.length})`}</div>
      {speakers.length > 0 && (
        <>
          <div key={host} className={classes.speakerContainer}>
            <Typography variant="body1">{`${host} (Host)`}</Typography>
            {host && <SpeakerMenu chatBans={chatBans} eventBans={eventBans} speaker={host} type="speaker" />}
          </div>
          {speakers
            .filter((speaker: any) => speaker !== host)
            .sort(function(a: any, b: any) {
              return a.localeCompare(b);
            })
            .map((speaker: any) => (
              <div key={speaker} className={classes.speakerContainer}>
                <Typography variant="body1">
                  {localParticipant?.identity === speaker ? `${speaker} (You)` : speaker}
                </Typography>
                {host && <SpeakerMenu chatBans={chatBans} eventBans={eventBans} speaker={speaker} type="speaker" />}
              </div>
            ))}
        </>
      )}
    </div>
  );
}
