import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import {
  Typography,
  makeStyles,
  TextField,
  Grid,
  Button,
  InputLabel,
  Theme,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Divider,
} from '@material-ui/core';
import { appActionTypes, appStateTypes } from '../../../state/appState/appReducer';
import axios from 'axios';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0.5em',
    },
  },
  formContainer: {
    display: 'flex',
    flexFlow: 'wrap',
    justifyContent: 'space-between',
  },
  inputContainer: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 3.5em 0',
    width: '48%',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      margin: '1.5em 0 2em',
      width: '100%',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface CreateNewEventScreenProps {
  state: appStateTypes;
  dispatch: React.Dispatch<appActionTypes>;
  connect: () => void;
  user: any;
}

export default function CreateNewEventScreen({ state, dispatch, connect, user }: CreateNewEventScreenProps) {
  const classes = useStyles();
  const [subscriptionsList, setSubscriptionsList] = useState<string[] | undefined>();
  const [isGettingSubscriptions, setIsGettingSubscriptions] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState<string[] | undefined>();
  const [showSelect, setShowSelect] = useState(false);
  const isAdmin = user?.wp_participantType === 'administrator' || user?.wp_participantType === 'contributor';

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    // echo out friendly display
    dispatch({ type: 'set-event-name', eventName: `${event.target.value}` });
  };

  const handleSelectChange = (event: ChangeEvent<{}>) => {
    const target = event.target as HTMLInputElement;

    if ((event.currentTarget as HTMLInputElement).nodeName === 'LI') {
      setSelectedSubscription(typeof target.value === 'string' ? target.value.split(',') : target.value);
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (typeof selectedSubscription === 'undefined') return;
    // format room name
    dispatch({ type: 'set-event-name', eventName: `${state.eventName.trim()} | ${selectedSubscription}` });
  };

  useEffect(() => {
    if (typeof selectedSubscription === 'undefined') return;

    if (state.eventName.includes(` | ${selectedSubscription.join(',')}`)) {
      connect();
      // dispatch({ type: 'set-active-screen', activeScreen: ActiveScreen.DeviceSelectionScreen });
    }
  }, [state.eventName, selectedSubscription, connect]);

  async function getSubscriptionsList() {
    return await axios.post(
      `https://${state.originDomain === 'mam' ? 'moneyandmarkets' : 'banyanhill'}.com/graphql`,
      {
        query: `
        {
          pages(
            where: {categoryName: "Subscriptions", orderby: {field: TITLE, order: ASC}, status: PUBLISH}
            first: 60
          ) {
            edges {
              node {
                featuredImage {
                  node {
                    sourceUrl
                  }
                }
                pubcode
                slug
                title(format: RENDERED)
              }
            }
          }
        }
      `,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  if (typeof subscriptionsList === 'undefined' && !isGettingSubscriptions) {
    setIsGettingSubscriptions(true);
    getSubscriptionsList().then(list => {
      setSubscriptionsList((list.data as any)?.data.pages.edges);
      setIsGettingSubscriptions(false);
    });
  }

  return (
    <div>
      <Typography variant="h5" className={classes.gutterBottom}>
        Create new event
      </Typography>
      <form onSubmit={handleSubmit} className={classes.formContainer}>
        <div className={classes.inputContainer}>
          <div className={classes.textFieldContainer}>
            <InputLabel shrink htmlFor="input-user-name" style={{ fontWeight: 'bold' }}>
              Event name
            </InputLabel>
            <TextField
              id="input-user-name"
              autoFocus
              variant="outlined"
              fullWidth
              size="small"
              value={state.eventName}
              onChange={handleNameChange}
            />
          </div>
        </div>
        <div className={classes.inputContainer}>
          <div className={classes.textFieldContainer} style={{ marginBottom: '3.5em' }}>
            <InputLabel shrink htmlFor="select-service" style={{ fontWeight: 'bold' }}>
              Service
            </InputLabel>
            <Select
              multiple
              id="select-service"
              onChange={handleSelectChange}
              value={typeof selectedSubscription === 'undefined' ? [] : selectedSubscription}
              variant="outlined"
              displayEmpty
              fullWidth
              renderValue={value => {
                if ((value as string[]).length === 0) {
                  return subscriptionsList ? `Select Service(s)` : `Loading ...`;
                }

                return (value as string[]).join(', ');
              }}
              open={showSelect}
              onOpen={() => setShowSelect(true)}
              onClose={() => setShowSelect(false)}
            >
              <MenuItem value="" disabled>
                {subscriptionsList ? `Select Service(s)` : `Loading ...`}
              </MenuItem>
              {subscriptionsList &&
                subscriptionsList.length > 0 &&
                (subscriptionsList as any).map((subscription: any) => {
                  return (
                    <MenuItem key={subscription.node.pubcode} value={subscription.node.pubcode}>
                      <Checkbox
                        checked={
                          typeof selectedSubscription === 'undefined'
                            ? false
                            : selectedSubscription.indexOf(subscription.node.pubcode) > -1
                        }
                      />
                      <ListItemText primary={subscription.node.title} />
                    </MenuItem>
                  );
                })}
              <Divider />
              <div style={{ padding: '16px 16px 0 16px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.continueButton}
                  onClick={() => {
                    setShowSelect(false);
                  }}
                >
                  Close
                </Button>
              </div>
            </Select>
          </div>
        </div>
        <Grid container justifyContent={isAdmin ? `space-around` : `flex-end`}>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={!selectedSubscription}
            className={classes.continueButton}
            onClick={() => {
              dispatch({
                type: 'set-is-director',
                isDirector: true,
              });
            }}
          >
            Continue as Director
          </Button>
          {isAdmin && (
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={!selectedSubscription}
              className={classes.continueButton}
            >
              Continue as Presenter
            </Button>
          )}
        </Grid>
      </form>
    </div>
  );
}
