import { useEffect, useState } from 'react';
import useSyncContext from '../useSyncContext/useSyncContext';

export function useGlobalAppStateMap() {
  const { globalAppStateMap } = useSyncContext();
  const [globalAppState, setGlobalAppState] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    if (globalAppStateMap) {
      // Sets the list on load.
      globalAppStateMap.getItems({ pageSize: 100 }).then(paginator => {
        setGlobalAppState(paginator.items.map(item => item.data)[0]);
      });

      const handleItemUpdated = (update: any) => {
        setGlobalAppState(update.item.descriptor.data);
      };

      globalAppStateMap.on('itemAdded', (update: any) => {
        setGlobalAppState(update.item.descriptor.data);
      });

      globalAppStateMap.on('itemUpdated', handleItemUpdated);

      return () => {
        globalAppStateMap.off('itemUpdated', handleItemUpdated);
      };
    }
  }, [globalAppStateMap]);

  return globalAppState;
}
