import axios from 'axios';

export const apiClient = axios.create({
  baseURL: '/',
  headers: {
    'content-type': 'application/json',
  },
});

export const createStream = (user_identity: string, stream_name: string) =>
  apiClient.post<{
    token: string;
    room_sid: string;
    sync_object_names: {
      speakers_map: string;
      viewers_map: string;
      moderators_map: string;
      raised_hands_map: string;
      chat_bans_map: string;
      event_bans_map: string;
      global_app_state_map: string;
      user_document_notepad: string;
    };
  }>('create-stream', {
    user_identity,
    stream_name,
  });

export const joinStreamAsHost = (user_identity: string, stream_name: string) =>
  apiClient.post<{
    token: string;
    room_sid: string;
    sync_object_names: {
      speakers_map: string;
      viewers_map: string;
      moderators_map: string;
      raised_hands_map: string;
      global_app_state_map: string;
      user_document: string;
      user_document_notepad: string;
      chat_bans_map: string;
      event_bans_map: string;
    };
  }>('join-stream-as-host', {
    user_identity,
    stream_name,
  });

export const joinStreamAsSpeaker = (user_identity: string, stream_name: string) =>
  apiClient.post<{
    token: string;
    room_sid: string;
    sync_object_names: {
      speakers_map: string;
      viewers_map: string;
      moderators_map: string;
      raised_hands_map: string;
      global_app_state_map: string;
      user_document: string;
      user_document_notepad: string;
      chat_bans_map: string;
      event_bans_map: string;
    };
  }>('join-stream-as-speaker', {
    user_identity,
    stream_name,
  });

export const joinStreamAsModerator = (user_identity: string, stream_name: string) =>
  apiClient.post<{
    token: string;
    room_sid: string;
    sync_object_names: {
      speakers_map: string;
      viewers_map: string;
      moderators_map: string;
      raised_hands_map: string;
      global_app_state_map: string;
      user_document: string;
      user_document_notepad: string;
      chat_bans_map: string;
      event_bans_map: string;
    };
  }>('join-stream-as-moderator', {
    user_identity,
    stream_name,
  });

export const joinStreamAsViewer = (user_identity: string, stream_name: string) =>
  apiClient.post<{
    token: string;
    room_sid: string;
    sync_object_names: {
      speakers_map: string;
      viewers_map: string;
      moderators_map: string;
      raised_hands_map: string;
      global_app_state_map: string;
      user_document: string;
      user_document_notepad: string;
      chat_bans_map: string;
      event_bans_map: string;
    };
  }>('join-stream-as-viewer', {
    user_identity,
    stream_name,
  });

export const connectModeratorToPlayer = (user_identity: string, stream_name: string) =>
  apiClient.post<{
    success: boolean;
  }>('moderator-connected-to-player', {
    user_identity,
    stream_name,
  });

export const connectViewerToPlayer = (user_identity: string, stream_name: string) =>
  apiClient.post<{
    success: boolean;
  }>('viewer-connected-to-player', {
    user_identity,
    stream_name,
  });

export const reconnectViewer = (user_identity: string, stream_name: string) =>
  apiClient.post<{
    token: string;
    room_sid: string;
  }>('reconnect-viewer', {
    user_identity,
    stream_name,
  });

export const raiseHand = (user_identity: string, stream_name: string, hand_raised: boolean) =>
  apiClient.post('raise-hand', {
    user_identity,
    stream_name,
    hand_raised,
  });

export const sendSpeakerInvite = (user_identity: string, room_sid: string) =>
  apiClient.post('send-speaker-invite', {
    user_identity,
    room_sid,
  });

export const sendChatInvite = (viewer: string, host?: string) =>
  apiClient.post('send-chat-invite', {
    viewer,
    host,
  });

export const deleteStream = (stream_name: string) =>
  apiClient.post('delete-stream', {
    stream_name,
  });

export const getRooms = (token: string) =>
  apiClient.post('get-rooms', {
    token,
  });

export const removeSpeaker = (user_identity: string, room_name: string) =>
  apiClient.post('remove-speaker', {
    user_identity,
    room_name,
  });
