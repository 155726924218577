import { useEffect, useState } from 'react';
import useSyncContext from '../useSyncContext/useSyncContext';

export function useModeratorsMap() {
  const { moderatorsMap } = useSyncContext();
  const [moderators, setModerators] = useState<string[]>([]);

  useEffect(() => {
    const pageHandler = (paginator: any) => {
      setModerators((prevModerators: any) => {
        if (prevModerators.length > 0) {
          return [...prevModerators, ...paginator.items.map((item: any) => item.key)];
        } else {
          return [...paginator.items.map((item: any) => item.key)];
        }
      });

      return paginator.hasNextPage ? paginator.nextPage().then(pageHandler) : null;
    };

    if (moderatorsMap) {
      moderatorsMap
        .getItems({ pageSize: 100, limit: 5000 })
        .then(pageHandler)
        .catch(error => {
          console.error('Map getItems() failed', error);
        });

      const handleItemAdded = (args: any) => {
        setModerators(prevModerators => [...prevModerators, args.item.key]);
      };

      const handleItemRemoved = (args: any) => {
        setModerators(prevModerators => prevModerators.filter(i => i !== args.key));
      };

      moderatorsMap.on('itemAdded', handleItemAdded);
      moderatorsMap.on('itemRemoved', handleItemRemoved);

      return () => {
        moderatorsMap.off('itemAdded', handleItemAdded);
        moderatorsMap.off('itemRemoved', handleItemRemoved);
      };
    }
  }, [moderatorsMap]);

  return moderators;
}
