import React, { useRef, useState, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import useSyncContext from '../../hooks/useSyncContext/useSyncContext';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      background: 'rgba(0,0,0,0.04)',
      height: '100%',
      padding: '1rem',
    },
  })
);

const Tiny = ({ padId, preContent }) => {
  const classes = useStyles();
  const [content, setContent] = useState(preContent);
  const editorRef = useRef(null);
  const { userDocumentNotes } = useSyncContext();

  useEffect(() => {
    // Load the existing Document
    if (userDocumentNotes?.data?.content?.[padId]) {
      setContent(userDocumentNotes?.data?.content?.[padId]);
    }
  }, [userDocumentNotes, padId]);

  function updateUserDocument(e) {
    let prevContent = userDocumentNotes?.data?.content;

    if (userDocumentNotes?.data?.content) {
      prevContent[padId] = e;
      userDocumentNotes?.update({
        content: prevContent,
      });
    } else {
      userDocumentNotes?.update({
        content: {
          [padId]: e,
        },
      });
    }
  }

  return (
    <>
      <div className={clsx(classes.container)}>
        <Editor
          tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
          onInit={(evt, editor) => (editorRef.current = editor)}
          initialValue={content}
          id={padId}
          onEditorChange={e => updateUserDocument(e)}
          init={{
            height: '100%',
            menubar: false,
            plugins: [
              'advlist',
              'autolink',
              'lists',
              'link',
              'image',
              'charmap',
              'preview',
              'anchor',
              'searchreplace',
              'visualblocks',
              'code',
              'fullscreen',
              'insertdatetime',
              'media',
              'table',
              'code',
              'help',
              'wordcount',
            ],
            toolbar:
              'undo redo | blocks | ' +
              'bold italic forecolor backcolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            default_link_target: '_blank',
            extended_valid_elements: 'a[href|target=_blank]',
          }}
        />
      </div>
    </>
  );
};

export default Tiny;
