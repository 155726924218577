import React from 'react';
import ViewersList from '../ViewersList/ViewersList';
import ModeratorsList from '../ModeratorsList/ModeratorsList';
import SpeakersList from '../SpeakersList/SpeakersList';

interface ParticipantWindowProps {
  chatBans: any;
  eventBans: any;
  host?: any;
  speakers: any;
}

export default function ParticipantWindow({ chatBans, eventBans, host, speakers }: ParticipantWindowProps) {
  return (
    <>
      <SpeakersList chatBans={chatBans} eventBans={eventBans} host={host} speakers={speakers} />
      <ModeratorsList chatBans={chatBans} eventBans={eventBans} />
      <ViewersList chatBans={chatBans} eventBans={eventBans} />
    </>
  );
}
