import React from 'react';
import { Typography, makeStyles, Theme, Divider, Paper, ButtonBase } from '@material-ui/core';
import { appActionTypes, appStateTypes } from '../../../state/appState/appReducer';
import CreateEventIcon from '../../../icons/CreateEventIcon';
import JoinEventIcon from '../../../icons/JoinEventIcon';
import RightArrowIcon from '../../../icons/RightArrowIcon';
import { isMobile } from '../../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0.5em',
    },
  },
  paperContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '50%',
    justifyContent: 'space-between',
  },
  paper: {
    width: '100%',
    height: '75px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    transition: 'all 0.2s linear',
    '&:hover': {
      backgroundColor: '#EFEFEF',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0.8em 0',
    },
  },
  innerPaperContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  bodyTypography: {
    color: '#606B85',
    fontWeight: 'bold',
  },
  leftIcon: {
    margin: '0 0.7em 0 0.5em',
  },
  rightArrowIcon: {
    margin: '0.5em 0.5em 0 0',
  },
  backButton: {
    marginTop: '0.8em',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface CreateOrJoinScreenProps {
  state: appStateTypes;
  dispatch: React.Dispatch<appActionTypes>;
  user?: any;
}

export default function CreateOrJoinScreen({ state, dispatch, user }: CreateOrJoinScreenProps) {
  const classes = useStyles();

  if (user?.wp_participantType === 'subscriber' || !user?.wp_participantType) {
    dispatch({ type: 'set-participant-type', participantType: 'viewer' });
    return null;
  }

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        Create or join?
      </Typography>
      <Typography className={classes.gutterBottom}>
        Create your own event or join one that's already happening.
      </Typography>
      <Divider style={!isMobile ? { margin: '0 0 40px 0' } : { margin: '0 0 20px 0' }} />
      <div className={classes.paperContainer}>
        <ButtonBase focusRipple onClick={() => dispatch({ type: 'set-participant-type', participantType: 'host' })}>
          <Paper className={classes.paper} elevation={3}>
            <div className={classes.innerPaperContainer}>
              <div className={classes.leftIcon}>
                <CreateEventIcon />
              </div>
              <Typography className={classes.bodyTypography}>Create a new event</Typography>
            </div>
            <div className={classes.rightArrowIcon}>
              <RightArrowIcon />
            </div>
          </Paper>
        </ButtonBase>

        <ButtonBase focusRipple onClick={() => dispatch({ type: 'set-participant-type', participantType: 'guest' })}>
          <Paper className={classes.paper} elevation={3}>
            <div className={classes.innerPaperContainer}>
              <div className={classes.leftIcon}>
                <JoinEventIcon />
              </div>
              <Typography className={classes.bodyTypography}>Join an event</Typography>
            </div>
            <div className={classes.rightArrowIcon}>
              <RightArrowIcon />
            </div>
          </Paper>
        </ButtonBase>
      </div>
    </>
  );
}
