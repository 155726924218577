import React from 'react';

export default function BanyanLogo({ className }: { className?: string }) {
  return (
    <div className={className}>
      <img
        alt="Banyan Hill"
        src="https://cdn.banyanhill.com/wp-content/uploads/2021/05/05110622/cropped-180x180_BanyanFavicon.jpg"
        width="90"
        height="90"
      />
    </div>
  );
}
