import React from 'react';
import RoomBansList from '../BansList/RoomBansList';
import ChatBansList from '../BansList/ChatBansList';

interface BansWindowProps {
  chatBans: any;
  eventBans: any;
}

export default function BansWindow({ chatBans, eventBans }: BansWindowProps) {
  return (
    <>
      <RoomBansList chatBans={chatBans} eventBans={eventBans} />
      <ChatBansList chatBans={chatBans} eventBans={eventBans} />
    </>
  );
}
