import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import BanyanLogo from './BanyanLogo';
import MoneyLogo from './MoneyLogo';
import { useAppState } from '../../state';
import UserMenu from './UserMenu/UserMenu';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgb(40, 42, 43)',
    height: '100%',
  },
  transparentBackground: {
    background: 'rgba(0, 0, 0, 0.8)',
    position: 'fixed',
    height: 'auto',
    top: 0,
    left: 0,
    right: 0,
    bottom: `${theme.footerHeight}px`,
    zIndex: 100,
  },
  container: {
    position: 'relative',
    flex: '1',
  },
  innerContainer: {
    display: 'flex',
    width: '888px',
    height: '400px',
    borderRadius: '8px',
    boxShadow: '0px 2px 4px 0px rgba(40, 42, 43, 0.3)',
    overflow: 'hidden',
    position: 'relative',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      height: 'auto',
      width: 'calc(100% - 40px)',
      margin: 'auto',
      maxWidth: '400px',
    },
  },
  swooshContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.brandBackgroundColor,
    backgroundSize: 'cover',
    width: '296px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100px',
      backgroundPositionY: '140px',
    },
  },
  logoContainer: {
    position: 'absolute',
    width: '210px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      width: '90%',
      textAlign: 'initial',
      '& $logoCircleSurround': {
        width: '70px',
        height: '70px',
        margin: '0 20px 0 0',
      },
      '& img': {
        width: '50px',
        height: '50px',
      },
    },
  },
  logoCircleSurround: {
    width: '130px',
    height: '130px',
    borderRadius: '50%',
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto 20px auto',
  },
  content: {
    background: 'white',
    width: '100%',
    padding: '2em 4em',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '1.5em 1.5em',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '3em 2em',
    },
  },
  title: {
    color: 'white',
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      fontSize: '1.1rem',
    },
  },
  versionNum: {
    position: 'absolute',
    left: '20px',
    bottom: '10px',
    color: '#fff',
    fontSize: '12px',
  },
}));

interface IntroContainerProps {
  children: React.ReactNode;
  transparentBackground?: boolean;
}

const IntroContainer = (props: IntroContainerProps) => {
  const classes = useStyles();
  const { user, appState } = useAppState();
  const location = useLocation();

  return (
    <div className={clsx(classes.background, { [classes.transparentBackground]: props.transparentBackground })}>
      {user && location.pathname !== '/login' && <UserMenu />}
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <div className={classes.swooshContainer}>
            <div className={classes.logoContainer}>
              {appState.originDomain === 'mam' ? (
                <MoneyLogo className={classes.logoCircleSurround} />
              ) : (
                <BanyanLogo className={classes.logoCircleSurround} />
              )}
              <Typography variant="h6" className={classes.title}>
                {appState.originDomain === 'mam' ? 'Money and Markets Live' : 'Banyan Hill Live'}
              </Typography>
            </div>
          </div>
          <div className={classes.content}>{props.children}</div>
        </div>
      </div>
      <div className={classes.versionNum}>v: 2023.11.17</div>
    </div>
  );
};

export default IntroContainer;
