import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Location } from 'history';
import { apiClient } from '../api/api';

export function getPasscode(location: Location) {
  const match = location.search.match(/[?&]passcode=(\d+|\S+).*$/);
  const passcode = match ? match[1] : window.sessionStorage.getItem('passcode');
  return passcode;
}

export function getToken(location: Location) {
  const match = location.search.match(/[?&]token=([\w-]*\.[\w-]*\.[\w-]*$).*$/);
  const token = match ? match[1] : window.sessionStorage.getItem('token');
  return token;
}

export async function verifyAuth(username: string | null, password: string | null, passcode?: string, token?: string) {
  const encodedCreds = Buffer.from(`${username}:${password}`).toString('base64');

  return fetch('/verify-auth', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: passcode ? `${passcode}` : `Basic ${encodedCreds}`,
    },
    body: token ? JSON.stringify({ token: token }) : '',
  }).then(async res => {
    const jsonResponse = await res.json();
    if (res.status === 401) {
      return {
        isValid: false,
        error: jsonResponse.error?.message,
      };
    }
    if (res.ok) {
      return {
        isValid: true,
        display_name: jsonResponse.data?.display_name,
        domain: jsonResponse.data?.domain,
        pubcodes: jsonResponse.data?.pubcodes,
        roles: jsonResponse.data?.roles,
      };
    }
  });
}

export function getErrorMessage(message: string) {
  switch (message) {
    case 'passcode incorrect':
      return 'Passcode is incorrect';
    case 'passcode expired':
      return 'Passcode has expired';
    default:
      return message;
  }
}

export default function usePasscodeAuth() {
  const history = useHistory();
  const location = useLocation();

  const [user, setUser] = useState<{
    displayName: undefined;
    domain: undefined;
    wp_participantType: undefined;
    pubcodes: undefined;
    photoURL: undefined;
    passcode: string;
    token: string;
  } | null>(null);
  const [isAuthReady, setIsAuthReady] = useState(false);

  // add passcode to Authorization header for each api request:
  useEffect(() => {
    const inderceptorId = apiClient.interceptors.request.use(config => {
      config.headers!.authorization = `${user?.passcode}`;
      return config;
    });
    return () => {
      apiClient.interceptors.request.eject(inderceptorId);
    };
  }, [user]);

  useEffect(() => {
    const passcode = getPasscode(location);
    const token = getToken(location);

    if (token && !user) {
      verifyAuth(null, null, undefined, token)
        .then(verification => {
          if (verification?.isValid) {
            setUser({
              displayName: verification.display_name,
              domain: verification.domain,
              wp_participantType: verification.roles,
              pubcodes: verification.pubcodes,
              passcode: `Basic ${Buffer.from(`user:pass`).toString('base64')}`,
              token: token,
            } as any);
          }
        })
        .then(() => setIsAuthReady(true));
    }

    if (passcode && !user) {
      verifyAuth(null, null, passcode)
        .then(verification => {
          if (verification?.isValid) {
            setUser({ passcode } as any);
            window.sessionStorage.setItem('passcode', passcode);
            history.replace(location.pathname);
          }
        })
        .then(() => setIsAuthReady(true));
    } else {
      setIsAuthReady(true);
    }
  }, [history, location, user]);

  const signIn = useCallback((passcode: string, username: string, password: string, token: string) => {
    return verifyAuth(username, password, passcode, token).then(verification => {
      if (verification?.isValid) {
        if (passcode) {
          setUser({ passcode } as any);
          window.sessionStorage.setItem('passcode', passcode);
        } else {
          setUser({
            displayName: verification.display_name,
            domain: verification.domain,
            wp_participantType: verification.roles,
            pubcodes: verification.pubcodes,
            passcode: `Basic ${Buffer.from(`${username}:${password}`).toString('base64')}`,
          } as any);
          setIsAuthReady(true);
        }
      } else {
        throw new Error(getErrorMessage(verification?.error));
      }
    });
  }, []);

  const signOut = useCallback(() => {
    setUser(null);
    window.sessionStorage.removeItem('passcode');
    return Promise.resolve();
  }, []);

  return { user, isAuthReady, signIn, signOut };
}
