import React, { useState, ChangeEvent, FormEvent } from 'react';
import {
  Typography,
  makeStyles,
  Divider,
  Grid,
  Button,
  InputLabel,
  Theme,
  Select,
  MenuItem,
  Link,
  CircularProgress,
  Paper,
  ButtonBase,
} from '@material-ui/core';
import { appActionTypes, appStateTypes } from '../../../state/appState/appReducer';
import CreateEventIcon from '../../../icons/CreateEventIcon';
import RightArrowIcon from '../../../icons/RightArrowIcon';
import { isMobile } from '../../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0.5em',
    },
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 3.5em',
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  backButton: {
    marginTop: '0.8em',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  togglePassLink: {
    color: '#ff6600',
    cursor: 'pointer',
  },
  paperContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '50%',
    justifyContent: 'space-between',
  },
  paper: {
    width: '100%',
    height: '75px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    transition: 'all 0.2s linear',
    '&:hover': {
      backgroundColor: '#EFEFEF',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0.8em 0',
    },
  },
  innerPaperContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  bodyTypography: {
    color: '#606B85',
    fontWeight: 'bold',
  },
  leftIcon: {
    margin: '0 0.7em 0 0.5em',
  },
  rightArrowIcon: {
    margin: '0.5em 0.5em 0 0',
  },
}));

interface JoinHostEventScreenProps {
  state: appStateTypes;
  dispatch: React.Dispatch<appActionTypes>;
  connect: () => void;
  getRooms: any;
  user: any;
}

export default function JoinHostEventScreen({ state, dispatch, connect, getRooms, user }: JoinHostEventScreenProps) {
  const classes = useStyles();
  const [roomsList, setRoomsList] = useState<string[] | undefined>();
  const [isGettingRooms, setIsGettingRooms] = useState(false);
  const isAdmin = user?.wp_participantType === 'administrator' || user?.wp_participantType === 'contributor';

  const handleSelectChange = (event: ChangeEvent<{}>) => {
    const target = event.target as HTMLInputElement;
    dispatch({ type: 'set-event-name', eventName: target.value });
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    connect();
  };

  async function getRoomsList() {
    return await getRooms(user.token ? user.token : undefined);
  }

  if (typeof roomsList === 'undefined' && !isGettingRooms) {
    setIsGettingRooms(true);
    getRoomsList().then(list => {
      setRoomsList((list as any)?.data?.roomList);
      setIsGettingRooms(false);
    });
  }

  return (
    <>
      {isGettingRooms && (
        <Grid container justifyContent="center" alignItems="center" direction="column" style={{ height: '100%' }}>
          <div>
            <CircularProgress variant="indeterminate" />
          </div>
          <div>
            <Typography style={{ fontWeight: 'bold', fontSize: '16px' }}>Getting Active Events ...</Typography>
          </div>
        </Grid>
      )}
      {((!isGettingRooms && !roomsList) || (roomsList && roomsList.length !== 0)) && (
        <>
          <Typography variant="h5" className={classes.gutterBottom}>
            Join Event
          </Typography>
          <form onSubmit={handleSubmit}>
            <div className={classes.inputContainer}>
              <div className={classes.textFieldContainer} style={{ marginBottom: '3.5em' }}>
                <InputLabel shrink htmlFor="select-room" style={{ fontWeight: 'bold' }}>
                  Event name
                </InputLabel>
                <Select
                  onChange={handleSelectChange}
                  value={state.eventName || '0'}
                  variant="outlined"
                  id="select-room"
                  fullWidth
                >
                  <MenuItem value="0" selected disabled>
                    {!isGettingRooms ? `Select an Active Room` : `Loading ...`}
                  </MenuItem>
                  {roomsList &&
                    (roomsList as any).map((room: any) => {
                      return (
                        <MenuItem value={room.uniqueName} key={room.sid}>
                          {room.uniqueName}
                        </MenuItem>
                      );
                    })}
                </Select>
                <Link
                  style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}
                  onClick={() => {
                    setRoomsList([]);
                  }}
                  className={classes.togglePassLink}
                >
                  Join by event name
                </Link>
              </div>
            </div>
            <Grid container justifyContent="space-around">
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={!state.eventName}
                className={classes.continueButton}
                onClick={() => {
                  dispatch({
                    type: 'set-is-director',
                    isDirector: true,
                  });
                }}
              >
                Continue as Director
              </Button>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={!state.eventName}
                className={classes.continueButton}
                onClick={() => {
                  if (!isAdmin) {
                    dispatch({ type: 'set-participant-type', participantType: 'moderator' });
                  }
                }}
              >
                Continue as {isAdmin ? `Presenter` : `Moderator`}
              </Button>
            </Grid>
          </form>
        </>
      )}
      {!isGettingRooms && roomsList && roomsList.length === 0 && (
        <>
          <Typography variant="h5" className={classes.gutterBottom}>
            Join Event
          </Typography>
          <Typography variant="h6" className={classes.gutterBottom}>
            No active events found!
          </Typography>
          <Typography className={classes.gutterBottom}>
            <Link
              onClick={() => {
                setRoomsList(undefined);
              }}
              className={classes.togglePassLink}
            >
              Refresh the event list
            </Link>{' '}
            to check again.
          </Typography>
          <Divider style={!isMobile ? { margin: '0 0 40px 0' } : { margin: '0 0 20px 0' }} />
          <div className={classes.paperContainer}>
            <ButtonBase focusRipple onClick={() => dispatch({ type: 'set-participant-type', participantType: 'host' })}>
              <Paper className={classes.paper} elevation={3}>
                <div className={classes.innerPaperContainer}>
                  <div className={classes.leftIcon}>
                    <CreateEventIcon />
                  </div>
                  <Typography className={classes.bodyTypography}>Create a new event</Typography>
                </div>
                <div className={classes.rightArrowIcon}>
                  <RightArrowIcon />
                </div>
              </Paper>
            </ButtonBase>
          </div>
        </>
      )}
    </>
  );
}
