import React, { ChangeEvent, FormEvent, useEffect } from 'react';
import { appActionTypes, ActiveScreen, appStateTypes } from '../../../state/appState/appReducer';
import { Typography, makeStyles, TextField, Grid, Button, InputLabel, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0.5em',
    },
  },
  welcome: {
    color: '#606B85',
    marginBottom: '0.3em',
    fontWeight: 'bold',
  },
  askName: {
    fontWeight: 'bold',
    marginBottom: '2.3em',
    color: '#606B85',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 3.5em',
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface ParticipantNameScreenProps {
  state: appStateTypes;
  dispatch: React.Dispatch<appActionTypes>;
  user?: any;
}

export default function ParticipantNameScreen({ state, dispatch, user }: ParticipantNameScreenProps) {
  const classes = useStyles();

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'set-participant-name', participantName: event.target.value });
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    dispatch({
      type: 'set-participant-name',
      participantName: (document.getElementById('input-user-name') as HTMLInputElement).value.trim(),
    });
    dispatch({ type: 'set-active-screen', activeScreen: ActiveScreen.CreateOrJoinScreen });
  };

  useEffect(() => {
    if (user.displayName !== undefined && user.displayName !== '') {
      dispatch({ type: 'set-participant-name', participantName: user.displayName });
      dispatch({ type: 'set-active-screen', activeScreen: ActiveScreen.CreateOrJoinScreen });
    }
  }, [dispatch, user.displayName]);

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        Welcome!
      </Typography>
      <Typography className={classes.gutterBottom}>Please enter a display name below.</Typography>
      <Typography className={classes.gutterBottom}>
        Offensive or vulger names will be removed and/or banned from participating. We reserve the right to remove you
        from the room at any time, for any reason.
      </Typography>
      <form onSubmit={handleSubmit}>
        <div className={classes.inputContainer}>
          <div className={classes.textFieldContainer}>
            <InputLabel
              htmlFor="input-user-name"
              style={{ fontWeight: 'bold', fontSize: '0.8rem', marginBottom: '0.5em' }}
            >
              Full Name
            </InputLabel>
            <TextField
              id="input-user-name"
              autoFocus
              variant="outlined"
              fullWidth
              size="small"
              value={state.participantName}
              onChange={handleNameChange}
            />
          </div>
        </div>
        <Grid container justifyContent="flex-end">
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={!state.participantName}
            className={classes.continueButton}
          >
            Continue
          </Button>
        </Grid>
      </form>
    </>
  );
}
