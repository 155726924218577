import { useEffect, useState } from 'react';
import useSyncContext from '../useSyncContext/useSyncContext';

export function useChatBansMap() {
  const { chatBansMap } = useSyncContext();
  const [chatBans, setChatBans] = useState<string[]>([]);

  useEffect(() => {
    if (chatBansMap) {
      // Sets the list on load. Limiting to first 100 viewers who are banned from chat
      chatBansMap.getItems({ pageSize: 100 }).then(paginator => {
        setChatBans(paginator.items.map(item => item.key));
      });

      const handleItemAdded = (args: any) => {
        setChatBans(prevChatBans => [...prevChatBans, args.item.key]);
      };

      const handleItemRemoved = (args: any) => {
        setChatBans(prevChatBans => prevChatBans.filter(i => i !== args.key));
      };

      chatBansMap.on('itemAdded', handleItemAdded);
      chatBansMap.on('itemRemoved', handleItemRemoved);

      return () => {
        chatBansMap.off('itemAdded', handleItemAdded);
        chatBansMap.off('itemRemoved', handleItemRemoved);
      };
    }
  }, [chatBansMap]);

  return chatBans;
}
