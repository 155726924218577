import React from 'react';

export default function RightArrowIcon() {
  return (
    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.3145 7.64551C17.3145 7.37305 17.2002 7.10938 16.998 6.91602L11.2236 1.1416C10.9951 0.921875 10.7578 0.825195 10.5117 0.825195C9.94922 0.825195 9.54492 1.2207 9.54492 1.75684C9.54492 2.03809 9.65918 2.27539 9.83496 2.45117L11.8125 4.45508L14.3613 6.78418L12.3223 6.66113H1.66992C1.08105 6.66113 0.676758 7.06543 0.676758 7.64551C0.676758 8.2168 1.08105 8.62109 1.66992 8.62109H12.3223L14.3613 8.49805L11.8125 10.8271L9.83496 12.8311C9.65918 13.0068 9.54492 13.2441 9.54492 13.5254C9.54492 14.0615 9.94922 14.457 10.5117 14.457C10.7578 14.457 10.9951 14.3604 11.2061 14.1582L16.998 8.36621C17.2002 8.17285 17.3145 7.90918 17.3145 7.64551Z"
        fill="#0263E0"
      />
    </svg>
  );
}
