import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useAppState } from '../../state';
import { SpeakerMenu } from '../SpeakersList/SpeakerMenu/SpeakerMenu';

interface ChatBansListProps {
  chatBans: any;
  eventBans: any;
}

const useStyles = makeStyles({
  header: {
    fontWeight: 'bold',
    padding: '0.8em 0',
  },
  viewersContainer: {
    padding: '0.4em 1em',
    '& p': {
      padding: '0.75em 0',
    },
    overflow: 'auto',
  },
  viewerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    position: 'relative',
    '& button': {
      display: 'none',
      position: 'absolute',
      top: '0',
      right: '0',
    },
    '&:hover button': {
      display: 'inline-flex',
    },
  },
});

export default function ChatBansList({ eventBans, chatBans }: ChatBansListProps) {
  const { appState } = useAppState();
  const bansCount = chatBans.length;

  const classes = useStyles();

  return (
    <div className={classes.viewersContainer}>
      <div className={classes.header}>{`Muted (${bansCount})`}</div>
      {chatBans.map((viewer: any) => (
        <div key={viewer} className={classes.viewerContainer}>
          <Typography variant="body1">{appState.participantName === viewer ? `${viewer} (You)` : viewer} </Typography>
          {(appState.participantType === 'host' || appState.participantType === 'moderator') && (
            <SpeakerMenu chatBans={chatBans} eventBans={eventBans} speaker={viewer} type="viewer" />
          )}
        </div>
      ))}
    </div>
  );
}
