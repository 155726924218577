import React from 'react';

import { Button, Tooltip } from '@material-ui/core';
import MicNoneOutlinedIcon from '@material-ui/icons/MicNoneOutlined';
import MicOffOutlinedIcon from '@material-ui/icons/MicOffOutlined';

import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

export default function ToggleAudioButton(props: { disabled?: boolean; className?: string }) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideoContext();
  const hasAudioTrack = localTracks.some(track => track.kind === 'audio');

  return (
    <>
      <Tooltip title={isAudioEnabled ? 'Mute' : 'Unmute'} placement="top" PopperProps={{ disablePortal: true }}>
        <span>
          <Button
            className={props.className}
            onClick={toggleAudioEnabled}
            disabled={!hasAudioTrack || props.disabled}
            data-cy-audio-toggle
          >
            {isAudioEnabled ? <MicNoneOutlinedIcon fontSize="large" /> : <MicOffOutlinedIcon fontSize="large" />}
            {!hasAudioTrack ? 'No Audio' : ''}
          </Button>
        </span>
      </Tooltip>
    </>
  );
}
