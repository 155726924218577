import React, { createContext, useContext, useReducer, useState, useEffect } from 'react';
import { initialAppState, appReducer, appStateTypes, appActionTypes } from './appState/appReducer';
import { TwilioError } from 'twilio-video';
import { settingsReducer, initialSettings, Settings, SettingsAction } from './settings/settingsReducer';
import useActiveSinkId from './useActiveSinkId/useActiveSinkId';
import usePasscodeAuth from './usePasscodeAuth/usePasscodeAuth';
// import { User } from 'firebase';
import { MAM_DOMAINS } from '../constants';

export interface StateContextType {
  error: TwilioError | Error | null;
  setError(error: TwilioError | Error | null): void;
  user?: null | {
    displayName: undefined;
    domain: undefined;
    wp_participantType: undefined;
    pubcodes: undefined;
    photoURL: undefined;
    passcode?: string;
  };
  signIn?(passcode?: string, username?: string, password?: string): Promise<void>;
  signOut?(): Promise<void>;
  isAuthReady?: boolean;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
  appState: appStateTypes;
  appDispatch: React.Dispatch<appActionTypes>;
}

export const StateContext = createContext<StateContextType>(null!);

export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);
  const [appState, appDispatch] = useReducer(appReducer, initialAppState);

  const contextValue = {
    error,
    setError,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
    appState,
    appDispatch,
    ...usePasscodeAuth(),
  } as StateContextType;

  return <StateContext.Provider value={{ ...contextValue }}>{props.children}</StateContext.Provider>;
}

export function useAppState() {
  const context = useContext(StateContext);

  useEffect(() => {
    if (context?.appState?.originDomain === '') {
      context.appDispatch({
        type: 'set-origin',
        originDomain: MAM_DOMAINS.includes(window.location.origin) ? 'mam' : 'banyan',
      });
    }
  }, [context, context.appState.originDomain]);

  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
